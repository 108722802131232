import { Typography } from '@mui/material';
import React from 'react';
import CardWithLoader from './CardWithLoader';

const InfoCard = ({ 
    title,
    text,
    bottomText,
    contentLoading,
    fullHeight
}) =>
{
    return (
        <CardWithLoader 
            header={title}
            contentLoading={contentLoading}
            fullHeight={fullHeight ?? true}
        >
            <Typography 
                textAlign='center'
                fontSize='26px'
                fontWeight={500}
                color={'#f6f6f6'}
                marginTop={'5px'}
                marginBottom={'20px'}
            >
                {text}
            </Typography>
            <Typography 
                textAlign='center'
                fontSize='14px'
            >
                {bottomText}
            </Typography>
        </CardWithLoader>
    );
}

export default InfoCard;