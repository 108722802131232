import { Box, Button, CircularProgress, DialogContent, Modal } from '@mui/material';
import React, { useState } from 'react';
import ModalTitle from './ModalTitle';
import ModalResultState from './ModalResultState';
import InputTextField from '../../FormParts/InputTextField';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 500,
    maxWidth: 1000,
    bgcolor: '#2A3042',
    border: '2px solid #000',
    boxShadow: 24,
    borderRadius: 4,
};
  

const SingleInputModal = ({ 
    title,
    text,
    label,
    open, 
    handleClose, 
    handleOk, 
    resultState,
    loading
}) => {

    const [value, setValue] = useState(undefined);

    const onOkClick = (e) => {
        e.preventDefault();

        handleOk(value);
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <ModalTitle title={title} handleClose={handleClose} />
                <DialogContent style={{padding: '16px'}}>
                {
                    resultState ?
                    (
                        <ModalResultState resultState={resultState} />
                    ) 
                    :
                    (
                        <>
                            <div style={{ fontSize: '16px'}}>{text}</div>
                            <InputTextField  
                                id='input'
                                label={label}
                                handleValueChange={(_, value) => setValue(value)}
                                disabled={loading}
                                fullWidth
                                style={{
                                    marginTop: '8px',
                                    marginBottom: '8px'
                                }}
                            />
                            <div style={{
                                display: 'flex',
                                marginTop: '16px',
                                justifyContent: loading ? 'center' : 'flex-end',
                                alignItems: 'center'
                            }}>
                                {
                                    loading ? 
                                    (
                                        <CircularProgress />
                                    )
                                    :
                                    (
                                        <>
                                            <Button onClick={onOkClick} variant='outlined' style={{marginRight: '10px'}}>Ok</Button>
                                            <Button onClick={handleClose} color='error' variant='outlined'>Cancel</Button>
                                        </>
                                    )
                                }
                            </div>
                        </>
                    )
                }
                </DialogContent>
            </Box>
        </Modal>
    );
}
 
export default SingleInputModal;