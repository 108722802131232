import PageTitle from '../../components/common/PageTitle';
import { Grid } from '@mui/material';
import AreaChart from '../../components/Charts/AreaChart';
import { getDiscordMembersCountHistory, getInstagramFollowersCountHistory, getYoutubeSubscribersCountHistory } from '../../services/serviceNetworksService';


const SocialNetworksPage = () => {

    return ( 
        <>
            <PageTitle title='Društvene mreže'/>

            <Grid container justifyContent={'center'} spacing={2}>
                <Grid item md={6} xs={12}>
                    <AreaChart
                        title={'Discord članovi'}
                        getData={getDiscordMembersCountHistory}
                        loadData={true}
                        seriesName={'Članovi'}
                        type='datetime'
                        tooltip={{x: { format: 'dd. MMM yyyy. HH:mm' }}}
                    />
                </Grid>

                <Grid item md={6} xs={12}>
                    <AreaChart
                        title={'Instagram pratioci'}
                        getData={getInstagramFollowersCountHistory}
                        loadData={true}
                        seriesName={'Pratioci'}
                        type='datetime'
                        tooltip={{x: { format: 'dd. MMM yyyy. HH:mm' }}}
                    />
                </Grid>
                
                <Grid item md={6} xs={12}>
                    <AreaChart
                        title={'Youtube pratioci'}
                        getData={getYoutubeSubscribersCountHistory}
                        loadData={true}
                        seriesName={'Pratioci'}
                        type='datetime'
                        tooltip={{x: { format: 'dd. MMM yyyy. HH:mm' }}}
                    />
                </Grid>
            </Grid>
        </> 
    );
}
 
export default SocialNetworksPage;