import React, { useEffect, useState } from 'react';
import './Notifications.scss';
import SimpleBar from 'simplebar-react';
import NotificationItem from './NotificationItem';
import { getNotifications } from '../../../../services/notifications';
import Divider from '@mui/material/Divider';

const Notifications = () => {
    const [notificationsList, setNotificationList] = useState([]);

    useEffect(() => {
        getNotifications().then(res => {
            if (res.status === 200)
                setNotificationList(res.data);
        }).catch(e => {
            console.error(e);
        });
    }, []);

    return (
        <div className="notifications">
            <button type="button" className="notifications__button btn header-item noti-icon waves-effect" id="page-header-notifications-dropdown" data-toggle="dropdown">
                <i
                    id="notifications-bell"
                    className={"bx bx-bell" + (notificationsList?.length > 0 ? ' bx-tada' : '')}
                />
                <span
                    id="notifications-quantity"
                    className="badge badge-danger badge-pill"
                    style={{
                        display: notificationsList?.length > 0 ? 'block' : 'none'
                    }}
                >
                    {notificationsList?.length ?? '?'}
                </span>
            </button>

            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" aria-labelledby="page-header-notifications-dropdown">
                <div style={{ padding: '0.1rem 1rem' }}>
                    <h6 className="m-0" style={{ fontSize: '13px', fontWeight: '500', padding: '0.6rem 0 0.8rem 0' }}>Obaveštenja</h6>
                </div>
                <Divider />
                <SimpleBar id="notifications-list">
                    {
                        notificationsList.length > 0
                            ?
                            notificationsList.map((notification, index) => (
                                <NotificationItem
                                    key={index}
                                    title={notification.title}
                                    text={notification.text}
                                    divider={index !== notificationsList.length - 1}
                                />
                            ))
                            :
                            <NotificationItem text={'Nema novih obaveštenja.'} />
                    }
                </SimpleBar>
            </div>
        </div>
    );
};

export default Notifications;