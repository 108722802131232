import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropType from 'prop-types'

const MenuItem = ({ 
    title, 
    linkTo, 
    icon,
    handleMenuItemClick,
    activeTitles,
    getParentTitles,
    children
}) => {
    const [isActive, setActive] = useState(false);
    const location = useLocation();

    const getTitles = () => {
        let titles = [ ];
        if(getParentTitles)
            titles = getParentTitles();

        titles.push(title);
        return titles;
    }

    const setAsActiveMenuItem = () => {

        if(handleMenuItemClick)
        {
            const titles = getTitles();
            handleMenuItemClick(titles);
        }
    }

    useEffect(() => {
        
        const currentMenuTitles = getTitles();
        if(activeTitles?.length >= currentMenuTitles?.length && currentMenuTitles?.every((element, index) => element === activeTitles[index]))
            setActive(true);
        else
            setActive(false);

    }, [activeTitles])

    useEffect(() => {
        const currentLocation = location.pathname;
        if(currentLocation === linkTo)
            setAsActiveMenuItem();
    }, []);

    return (
        children && children.length > 0 ?
        (
            <CollapsibleMenuItem  
                title={title}
                icon={icon}
                handleMenuItemClick={handleMenuItemClick}
                activeTitles={activeTitles}
                getTitles={getTitles}
                isActive={isActive}
                children={children}
            />
        )  
        :
        (
            <li className={(isActive ? 'mm-active' : '')}>
                <Link to={linkTo} className={"waves-effect" + (isActive ? ' active' : '')} onClick={setAsActiveMenuItem} >
                    {icon} <span>{title}</span>
                </Link>
            </li>
        )
    );
}

MenuItem.PropType = {
    title: PropType.string,
    linkTo: PropType.string,
    icon: PropType.element | PropType.node
}
 
export default MenuItem;

const CollapsibleMenuItem = ({
    title, 
    icon, 
    handleMenuItemClick, 
    activeTitles, 
    getTitles,
    isActive, 
    children, 
}) => {
    const [isOpen, setOpen] = useState(false);

    const onCollapsibleMenuItemClick = (e) => {
        e.preventDefault();

        setOpen(!isOpen);
    }

    useEffect(() =>{
        
        setOpen(isActive);

    }, [isActive]);

    return (
        
        <li className={((isActive || isOpen) ? 'mm-active' : '')}>
            <a href="void" onClick={onCollapsibleMenuItemClick} className={"has-arrow waves-effect" + ((isActive || isOpen) ? ' mm-active' : '')}>
                {icon} <span>{title}</span>
            </a>
            <ul className={"sub-menu " + ((isActive || isOpen) ? 'mm-show' : 'mm-collapsing')}>
                {React.Children.map(children, child =>
                {
                    if (React.isValidElement(child))
                    {
                        return React.cloneElement(child, {
                            handleMenuItemClick: handleMenuItemClick,
                            activeTitles: activeTitles,
                            getParentTitles: getTitles
                        });
                    }

                    return child;
                })}
            </ul>
        </li>
    );
}
