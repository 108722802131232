import PropType from 'prop-types';
import React, { useEffect } from 'react';

const PageTitle = ({ title, onlyDocumentTitle }) => {
    useEffect(() => {
        document.title = `${title} | Balkan School Server Panel`
    });

    return ( 
        !onlyDocumentTitle && (
            <div style={{ paddingBottom: '24px', marginTop: '1rem', marginBottom: '1rem' }}>
                <h4 style={{ fontWeight: 600, fontSize: '16px', textTransform: 'uppercase' }}>{title}</h4>
            </div>
        )
    );
}

PageTitle.PropType = {
    title: PropType.string
}
 
export default PageTitle;