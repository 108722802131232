import { TableCell, TableRow } from '@mui/material';
import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const RenderCollapsibleRow = ({
    columns,
    row,
    depthData
}) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <>
            <RenderRowBase 
                columns={columns} 
                row={row} 
                collapseData={{ open: isOpen, setOpen: setOpen}}
                depthData={depthData}
            />
            {
                isOpen && row.children?.map((child, index) => (
                    <RenderRow key={index} columns={columns} row={child} depthData={{ ...depthData, depth: depthData.depth+1}} />
                ))
            }
        </>
    );
}

const RenderRowBase = ({
    columns,
    row,
    collapseData,
    depthData
}) => {
    const depthMargin = depthData.depth * 15;

    return (
        <TableRow style={{display: 'flex', backgroundColor: '#2a3042'}}>
            {
                columns.map((column, index) => {
                    const params = {
                        column: column,
                        row: row
                    }
                    return (
                        <TableCell 
                            key={index}
                            style={{
                                display: 'flex',
                                justifyContent: column.align ?? 'left',
                                flex: column.flex ?? 1,
                                minWidth: column.minWidth ?? 0,
                                borderRight: '1px solid rgba(81, 81, 81, 1)',
                                padding: '4px 16px 4px 16px',
                            }}
                        >
                            <div 
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {
                                    index === 0 ? <CollapseButton collapseData={collapseData} style={{marginLeft: depthMargin}}/> : <></>
                                }
                                {
                                    column.renderCell ? column.renderCell(params) : row[column.field]
                                }
                            </div>
                        </TableCell> 
                    )
                })
            }
        </TableRow>
    );
}

const CollapseButton = ({
    collapseData,
    style
}) =>
{
    return (
        <IconButton
            aria-label="expand row"
            size="small"
            style={{
                visibility: collapseData ? 'visible' : 'hidden',
                padding: '0px',
                ...style
            }}
            onClick={() => collapseData?.setOpen(!collapseData?.open)}
        >
            {collapseData?.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
    );
};

const RenderRow = ({
    columns,
    row,
    depthData
}) => {
    return (  
        row.children && row.children.length > 0 
        ? 
        <RenderCollapsibleRow 
            columns={columns} 
            row={row}
            depthData={depthData} 
        /> 
        : 
        <RenderRowBase 
            columns={columns} 
            row={row} 
            depthData={depthData}
        />
    );
}
 
export default RenderRow;