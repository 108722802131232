import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { Grid, Stack } from '@mui/material';

const ColumnChart = ({
    title,
    subtitle,
    categories,
    series,
    type,
    getData,
    loadData,
    reloadData,
    resetReloadData,
    seriesName,
    tooltip,
    yaxisFormatter,
    isStacked,
    chartOptions,
    height,
    header,
    filters,
}) => {
    const [seriesData, setSeriesData] = useState({ name: seriesName, data: [], categories: []});
    const [isLoading, setLoading] = useState(false);
    const [graphInited, setGraphInited] = useState(false);

    useEffect(() => {
        if(getData && ((loadData && !graphInited) || reloadData || filters))
        {
            setLoading(true);
            getData(filters).then(res => {
                setLoading(false);
                if(res.status === 200) {
                    if(isStacked)
                        setSeriesData(s => ({ ...s, ...res.data}));
                    else
                        setSeriesData(s => ({ ...s, data: res.data.map(d => d.y), categories: res.data.map(d => d.x)}));
                } 
            }).catch(e => {
                setLoading(false);
                console.error(e);
            });

            if(!graphInited)
                setGraphInited(true);

            if(reloadData)
                resetReloadData();
        }
    }, [loadData, reloadData, filters]);


    return (  
        <div 
            style={{
                paddingTop: '20px',
                paddingRight: '10px',
                background: '#2A3042'
            }}
        >
            <Stack>
                {
                    header && (
                        header
                    )
                }
                <ReactApexChart
                    options={{
                        states: {
                            active: {
                                filter: {
                                    type: 'none' /* none, lighten, darken */
                                }
                            }
                        },
                        xaxis: {
                            labels: {
                                datetimeUTC: false
                            },
                            type: type,
                            categories: categories ?? seriesData.categories
                        },
                        yaxis: {
                            labels: {
                                formatter: yaxisFormatter
                            }
                        },
                        dataLabels: {
                            enabled: false
                        },
                        title: {
                            offsetY: -5,
                            text: title,
                            align: 'left',
                            style: {
                                fontFamily: 'inherit',
                                fontSize: '16px'
                            }
                        },
                        subtitle: {
                            text: subtitle,
                            align: 'left'
                        },
                        theme: {
                            mode: 'dark',
                            palette: 'palette1'
                        },
                        chart: {
                            background: '#2A3042',
                            stacked: isStacked,
                            zoom: {
                                autoScaleYaxis: true,
                            },
                            toolbar: {
                                show: true,
                                tools: {
                                    download: false,
                                }
                            }
                        },
                        grid: {
                            borderColor: '#FFFFFF44'
                        },
                        
                        tooltip: tooltip,
                        ...(isStacked ? getStackedColumnChartOptions(yaxisFormatter) : {}),
                        ...chartOptions,
                    }}
                    series={series ?? (seriesData ? (isStacked ? seriesData.data : [seriesData]) : undefined)}
                    type='bar'
                    height={height ?? 'auto'}
                />
            </Stack>
            
        </div>
    );
}

ColumnChart.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    type: PropTypes.oneOf(['category', 'datetime', 'numeric']),
    loadData: PropTypes.bool,
    getData: PropTypes.func,
    reloadData: PropTypes.bool,
    resetReloadData: PropTypes.func
}
 
export default ColumnChart;

const getStackedColumnChartOptions = (formatter) => {
    return {
        plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 10,
              borderRadiusApplication: 'end', // 'around', 'end'
              borderRadiusWhenStacked: 'last', // 'all', 'last'
              dataLabels: {
                position: 'top',
                total: {
                  enabled: true,
                  formatter: formatter ? formatter : undefined,
                  style: {
                    fontSize: '12px',
                    fontWeight: 900,
                    color: '#F6F7F8',
                    textAlign: 'end'
                  }
                }
              }
            },
        }
    }
}