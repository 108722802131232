import { useSnackbar } from 'notistack';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import React, {Fragment} from "react";


const useNotification = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const action = key => (
        <Fragment>
            <IconButton onClick={() => { closeSnackbar(key) }}>
                <CloseIcon />
            </IconButton>
        </Fragment>
    );

    const appendSnackbar = (data) => {
        if(data?.message) {
            let variant = 'info';
            if(data.variant)
                variant = data.variant;

            enqueueSnackbar(data.message, {
                variant: variant,
                autoHideDuration: 5000,
                action
            });
        }
    }

    const showNotification = ({ message, variant }) => {
        appendSnackbar({
            message, 
            variant
        });
    }

    const showError = (message) => {
        showNotification({
            message: message,
            variant: 'error'
        })
    }

    const showWarning = (message) => {
        showNotification({
            message: message,
            variant: 'warning'
        })
    }

    const showSuccess = (message) => {
        showNotification({
            message: message,
            variant: 'success'
        })
    }

    const showInfo = (message) => {
        showNotification({
            message: message,
            variant: 'info'
        })
    }

    return {showNotification, showError, showWarning, showSuccess, showInfo};
};



export default useNotification;