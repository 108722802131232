import React, { useEffect, useState } from 'react';
import './Dropdown.scss';
import { Link } from 'react-router-dom';
import Pages from '../../../../constants/pages';
import AvatarImg from '../../../../assets/images/users/avatar.png';
import { Button } from '@mui/material';

const DropdownUserOptions = () => {
    const [userName, setUserName] = useState('te');

    const onLogoutClick = (e) => {
        localStorage.removeItem('userData');
    };

    useEffect(() => {
        const userData = localStorage.getItem('userData');
        if (userData)
            setUserName(JSON.parse(userData).username);
        else
            setUserName('Unknown user');
    }, []);

    return (
        <div className="dropdown d-inline-block">
            <Button 
                className="btn header-item waves-effect" 
                id="page-header-user-dropdown" 
                data-toggle="dropdown" 
                aria-haspopup="true"
                aria-expanded="false"
            >
                <img className="rounded-circle header-profile-user" src={AvatarImg} asp-append-version="true"
                        alt="Header Avatar" />
                <span className="d-none d-xl-inline-block ml-1" style={{ paddingLeft: '5px', paddingRight: '5px' }}>{userName}</span>
                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </Button>
            <div className="dropdown-menu dropdown-menu-right">
                <Link className="dropdown-item text-danger" to={Pages.LOGIN} onClick={onLogoutClick}>
                    <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> <span key="t-logout">Logout</span>
                </Link>
            </div>
        </div>
    );
};

export default DropdownUserOptions;