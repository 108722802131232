import { useState } from 'react';
import PageTitle from '../../../../components/common/PageTitle';
import DataTable from '../../../../components/DataTable/DataTable';
import { formatDateTimeWithSeconds } from '../../../../helpers/timeFormatter';
import { getRageLogTable } from '../../../../services/rageServices';
import { RAGE_LOG_TABLE } from '../../../../constants/rageLogTables';

const RageConnLogPage = () => {

    const [reload, setReload] = useState(false);

    const tableColumns = [
        { field: 'id', headerName: 'Id', flex: 1, minWidth: 90, hide: true },
        { field: 'uuid', headerName: 'UUID', flex: 0.5, minWidth: 85 },
        { field: 'name', headerName: 'Ime', flex: 1.25, minWidth: 160 },
        { field: 'connected', headerName: 'Ušao', type: 'date', flex: 1.25, minWidth: 165, valueFormatter: (params) => formatDateTimeWithSeconds(params.value) },
        { field: 'disconnected', headerName: 'Izašao', type: 'date', flex: 1.25, minWidth: 165, valueFormatter: (params) => formatDateTimeWithSeconds(params.value) },
        { field: 'tempId', headerName: 'Privr. Id', flex: 0.5, minWidth: 80 },
        { field: 'socialClub', headerName: 'SocialClub', flex: 1, minWidth: 140 },
        { field: 'socialClubId', headerName: 'SocialClub Id', flex: 1, minWidth: 120, hide: true },
        { field: 'ip', headerName: 'IP', flex: 1, minWidth: 120 },
        { field: 'hwid', headerName: 'HWID', flex: 1, minWidth: 300, hide: true },
    ];

    return ( 
        <>
            <PageTitle title='RageMP - Connection Logs'/>

            <DataTable 
                title={'Konekcije u poslednjih mesec dana'}
                columns={tableColumns}
                getData={() => getRageLogTable(RAGE_LOG_TABLE.ConnLog)}
                loadData={true}
                getRowId={(row) => row.id}
                initialSort={{
                    field: 'connected',
                    sort: 'desc'
                }}
                reloadData={reload}
                resetReloadData={() => setReload(false)}
            />
        </> 
    );
}
 
export default RageConnLogPage;