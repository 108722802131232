import React, { useEffect } from 'react';
import { useState } from 'react';
import PageTitle from '../../../components/common/PageTitle';
import { Grid } from '@mui/material';
import { getFakeAccounts } from '../../../services/sampServices';
import DataTable from '../../../components/DataTable/DataTable';
import { formatDate } from '../../../helpers/timeFormatter';
import { formatMoney } from '../../../helpers/moneyFormatter';

const SampFakeAccountsPage = ({
    serverNum
}) => {
    const [data, setData] = useState({ fakeAccounts: [], smallLevelAccountsWithMoney: [] });
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setLoading(true);

        getFakeAccounts(serverNum).then(res => {
            if(res.status === 200)
                setData(res.data);
        }).catch(e => {
            console.error(e);
        }).finally(() => {
            setLoading(false);
        })
    }, [serverNum]);

    const fakeAccountsColumns = [
        { field: 'name', headerName: 'Nalog', flex: 1, minWidth: 100 },
        { field: 'logins', headerName: 'Broj logina', type: 'number', flex: 1, minWidth: 80  },
        { field: 'playtimeHours', headerName: 'Sati u igri', type: 'number', flex: 1, minWidth: 80  },
        { field: 'registered', headerName: 'Registracija', type: 'date', flex: 1, minWidth: 100, valueFormatter: (params) => formatDate(params.value) },
        { field: 'faNum', headerName: 'Broj FA', type: 'number', flex: 1, minWidth: 120 },
        { field: 'ip', headerName: 'IP', flex: 1, minWidth: 120 },
    ];

    const muchMoneyColumns = [
        { field: 'name', headerName: 'Ime', flex: 1.5, minWidth: 150 },
        { field: 'money', headerName: 'Novac', type: 'number', flex: 1, minWidth: 120, valueFormatter: (params) => formatMoney(params.value)  },
        { field: 'level', headerName: 'Level', type: 'number', flex: 0.5, minWidth: 60  },
        { field: 'playtimeHours', headerName: 'Sati u igri', type: 'number', flex: 0.5, minWidth: 80  },
        { field: 'registered', headerName: 'Registracija', type: 'date', flex: 1.0, minWidth: 120, valueFormatter: (params) => formatDate(params.value) },
        { field: 'lastActivity', headerName: 'Poslednja aktivnost', type: 'date', flex: 1.2, minWidth: 120, valueFormatter: (params) => formatDate(params.value)  },
    ];

    return ( 
        <>
            <PageTitle title='Lažni nalozi'/>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <DataTable 
                        title={'Fake accounts (7 dana)'}
                        columns={fakeAccountsColumns}
                        staticData={data.fakeAccounts}
                        density={'compact'}
                        loading={loading}
                        getRowId={(row) => row.name}
                        initialSort={{
                            field: 'faNum',
                            sort: 'desc'
                        }}
                    />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <DataTable 
                        title={'Mali leveli + mnogo novca'}
                        columns={muchMoneyColumns}
                        staticData={data.smallLevelAccountsWithMoney}
                        density={'compact'}
                        loading={loading}
                        getRowId={(row) => row.name}
                        initialSort={{
                            field: 'money',
                            sort: 'desc'
                        }}
                    />
                </Grid>  
            </Grid>
        </> 
    );
}
 
export default SampFakeAccountsPage;