import ApiRoutes from '../constants/ApiRoutes.js';
import { getRequest } from './Api.js';


export const forceCacheJob = () => getRequest(ApiRoutes.Home.ForceCacheJob);
export const getLastCacheUpdate = () => getRequest(ApiRoutes.Home.GetLastCacheUpdate);
export const getSAMPServerData = (serverNum) => getRequest(ApiRoutes.Home.GetSAMPServerData + `?serverNum=${serverNum}`);
export const getRageServerData = () => getRequest(ApiRoutes.Home.GetRageServerData);
export const getAlerts = () => getRequest(ApiRoutes.Home.GetAlerts);
export const getUserPermissions = () => getRequest(ApiRoutes.Home.GetUserPermissions);
