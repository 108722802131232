import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import InfoCard from '../../../components/Cards/InfoCard';
import AreaChart from '../../../components/Charts/AreaChart';
import DataTable from '../../../components/DataTable/DataTable';
import PageTitle from '../../../components/common/PageTitle';
import { getAdminsStats, getRageStaffPage, getReportsAvgAnswerTimeDaily, getReportsDaily } from '../../../services/rageServices';
import useNotification from '../../../components/common/useNotification';
import { formatNumber } from './../../../helpers/numberFormatter';

const RageStaffPage = () => {
    const { showError } = useNotification();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({ totalReports: 0, totalReportsThisMonth: 0, totalPunishments: 0, totalPunishmentsThisMonth: 0 });

    useEffect(() => {
        setLoading(true);
        getRageStaffPage().then(res => {
            if(res.status === 200)
                setData(res.data);
        }).catch(e => {
            console.error(e);
            showError(`Došlo je do greške prilikom pribavljanja podataka za kartice!`);
        }).finally(() => {
            setLoading(false);
        })
    }, []);

    const adminsStatsColumns = [
        { field: 'uuid', headerName: 'UUID', flex: 0.5, minWidth: 75 },
        { field: 'name', headerName: 'Ime', flex: 2, minWidth: 150 },
        { field: 'punishmentsNum', headerName: 'Broj kazni', type: 'number', flex: 2, minWidth: 100, valueFormatter: (params) => formatNumber(params.value) },
        { field: 'reportsAnswered', headerName: 'Broj odgovora na report', type: 'number', flex: 2, minWidth: 100, valueFormatter: (params) => formatNumber(params.value) },
    ];


    return ( 
        <>
            <PageTitle title='RageMP - Staff'/>

            <Grid container justifyContent={'center'} spacing={2} marginBottom={'15px'}>
                <Grid item md={3}>
                    <InfoCard
                        title={'Ukupno reportova'}
                        text={formatNumber(data.totalReports)}
                    >
                    </InfoCard>
                </Grid>
                <Grid item md={3}>
                    <InfoCard
                        title={'Ukupno reportova ovog meseca'}
                        text={formatNumber(data.totalReportsThisMonth)}
                    >
                    </InfoCard>
                </Grid>
                <Grid item md={3}>
                    <InfoCard
                        title={'Ukupno kazni'}
                        text={formatNumber(data.totalPunishments)}
                    >
                    </InfoCard>
                </Grid>
                <Grid item md={3}>
                    <InfoCard
                        title={'Ukupno kazni ovog meseca'}
                        text={formatNumber(data.totalPunishmentsThisMonth)}
                    >
                    </InfoCard>
                </Grid>

                <Grid item container justifyContent={'center'} spacing={2}>
                    <Grid item md={6} xs={12}>
                        <AreaChart
                            title={'Broj reportova po danima'}
                            getData={getReportsDaily}
                            loadData={true}
                            seriesName={'Broj reportova'}
                            type='datetime'
                            tooltip={{x: { format: 'dd. MMM yyyy.' }}}
                        />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <AreaChart
                            title={'Prosečno vreme odgovora na report'}
                            getData={getReportsAvgAnswerTimeDaily}
                            loadData={true}
                            seriesName={'Minuti'}
                            type='datetime'
                            tooltip={{x: { format: 'dd. MMM yyyy.' }}}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <DataTable 
                            title={'Admini'}
                            columns={adminsStatsColumns}
                            getData={getAdminsStats}
                            loadData={true}
                            density={'compact'}
                            loading={loading}
                            getRowId={(row) => row.uuid}
                            initialSort={{
                                field: 'reportsAnswered',
                                sort: 'desc'
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </> 
    );
}
 
export default RageStaffPage;