import { useState } from 'react';
import PageTitle from '../../../../components/common/PageTitle';
import DataTable from '../../../../components/DataTable/DataTable';
import { formatDateTimeWithSeconds } from '../../../../helpers/timeFormatter';
import { getRageLogTable } from '../../../../services/rageServices';
import { RAGE_LOG_TABLE } from '../../../../constants/rageLogTables';
import { checkOrCrossIcon } from './../../../../components/common/CheckOrCross';
import { RAGE_CAUSE_OF_DEATH } from '../../../../constants/rageCauseOfDeath';

const RageDeathLogPage = () => {

    const [reload, setReload] = useState(false);

    const formatReason = (reason) => {
        return RAGE_CAUSE_OF_DEATH[reason] ?? reason;
    }

    const tableColumns = [
        { field: 'id', headerName: 'Id', flex: 1, minWidth: 90, hide: true },
        { field: 'playerUuid', headerName: 'UUID', flex: 0.5, minWidth: 85 },
        { field: 'playerName', headerName: 'Ime', flex: 1.25, minWidth: 180 },
        { field: 'killerId', headerName: 'Temp Id ubice', flex: 0.5, minWidth: 120 },
        { field: 'killerName', headerName: 'Ime ubice', flex: 1.25, minWidth: 180 },
        { field: 'reason', headerName: 'Razlog', flex: 1.5, minWidth: 180, valueFormatter: (params) => formatReason(params.value) },
        { field: 'inDmArena', headerName: 'U DM Areni', align: 'center', flex: 0.5, minWidth: 90, renderCell: (params) => checkOrCrossIcon(params.value) },
        { field: 'inHospital', headerName: 'U bolnici', align: 'center', flex: 0.5, minWidth: 90, renderCell: (params) => checkOrCrossIcon(params.value) },
        { field: 'date', headerName: 'Datum', type: 'date', flex: 1.25, minWidth: 165, valueFormatter: (params) => formatDateTimeWithSeconds(params.value) },
    ];

    return ( 
        <>
            <PageTitle title='RageMP - Death Logs'/>

            <DataTable 
                title={'Ubistva - poslednja 15 dana'}
                columns={tableColumns}
                getData={() => getRageLogTable(RAGE_LOG_TABLE.DeathLog)}
                loadData={true}
                getRowId={(row) => row.id}
                initialSort={{
                    field: 'date',
                    sort: 'desc'
                }}
                reloadData={reload}
                resetReloadData={() => setReload(false)}
            />
        </> 
    );
}
 
export default RageDeathLogPage;