import React, { useState } from 'react';
import '../AuthPage.scss';
import { Link } from 'react-router-dom';
import ProfileImg from "../../../assets/images/profile-img.png";
import BsLogo from "../../../assets/images/_bs/logo.png";
import Pages from '../../../constants/pages';
import BackgroundImage from '../../../assets/images/bg-auth-overlay.png';
import FormAbstract from '../../../components/Abstracts/FormAbstract';
import InputTextField from '../../../components/FormParts/InputTextField';
import { register } from '../../../services/auth';
import useNotification from '../../../components/common/useNotification';
import InputPassword from '../../../components/FormParts/InputPassword';

const RegisterPage = () => {
    const { showError, showSuccess, showWarning } = useNotification();
    const [formDisabled, setDisableForm] = useState(false);
    const [emailValid, setEmailValid] = useState(true);
    const [loading, setLoading] = useState(false);

    const onRegisterClick = (data) => {
        
        const email = data.email;
        if (!isEmailValid(email)) {
            setEmailValid(false);
            showWarning('Niste uneli validan email!');
            return;
        }

        setEmailValid(true);

        setLoading(true);
        register(data).then(res => {
            setLoading(false);
            if (res.status === 200) {
                showSuccess(res.data.message);
                setDisableForm(true);
            }
            else
                showError(res.data.message);
        }).catch(e => {
            setLoading(false);
            console.error(e);
            showError('Došlo je do greške prilikom logina! (2)');
        });
    };

    function isEmailValid(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    return (
        <div className="account-pages" style={{ backgroundImage: `url(${BackgroundImage})` }}>
            <div className="auth">
                <div className="auth__header">
                    <div className="auth__header-text">
                        <h5>Registracija naloga</h5>
                        <p>Unesite potrebne podatke kako biste registrovali nalog.</p>
                    </div>
                    <img src={ProfileImg} alt="" className="auth__header-img" />
                </div>
                <div className="auth__body">
                    <div className="auth__logo">
                        <img className="auth__logo-img" src={BsLogo} alt="" />
                    </div>
                    <form>
                        <RegisterForm
                            handleSave={onRegisterClick}
                            disableForm={formDisabled}
                            emailValid={emailValid}
                            loading={loading}
                        />
                        <div className="auth__change-page">
                            <Link to={Pages.LOGIN}>Posedujete nalog? Ulogujte se!</Link>
                        </div>
                    </form>
                </div>
            </div>
            <div className="auth-copyright">
                <div>
                    <p>© {new Date().getFullYear()}. Balkan School Community</p>
                </div>
            </div>
        </div>
    );
};

export default RegisterPage;

const RegisterForm = ({ handleSave, disableForm, emailValid, loading }) => {

    return (
        <div>
            <FormAbstract
                handleSave={handleSave}
                buttonText='Registruj se'
                disableForm={disableForm}
                buttonProps={{
                    fullWidth: true,
                    loading: loading,
                    style: {
                        marginTop: '20px'
                    }
                }}
            >
                <InputTextField
                    id="email"
                    label={'Email'}
                    fullWidth
                    type="email"
                    required
                    errorText={!emailValid ? 'Niste uneli validan email!' : ''}
                    error={!emailValid}
                />

                <InputTextField
                    id="username"
                    label={'Korisničko ime'}
                    fullWidth
                    type="text"
                    required
                />

                <InputPassword
                    id="password"
                    label="Lozinka"
                    type="password"
                    fullWidth
                    required
                />
            </FormAbstract>
        </div>
    );
};