import React, { useState } from 'react';
import './PermissionManagementPage.scss'
import PageTitle from '../../components/common/PageTitle';
import CollapsibleTable from '../../components/CollapsibleTable/CollapsibleTable';
import { createPermission, deletePermission, getAllPermissions } from '../../services/permissionManagement';
import { Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SingleInputModal from '../../components/Abstracts/Modals/SingleInputModal';
import { formatErrorMessage, formatMessage, formatSuccessMessage } from '../../components/Alerts/AlertBuilder';
import ConfirmModal from '../../components/Abstracts/Modals/ConfirmModal';


const PermissionManagementPage = () => {
    const [reloadTable, setReloadTable] = useState(false);
    const [createPermissionState, setCreatePermissionState] = useState({ open: false, parentId: undefined, text: '', resultState: undefined, loading: false });
    const [deletePermissionState, setDeletePermissionState] = useState({ isOpen: false, permission: undefined, resultState: undefined, loading: false });

    const onDeletePermissionClick = (e, permission) => {
        e.preventDefault();

        setDeletePermissionState(s => ({ ...s, isOpen: true, permission: permission, resultState: undefined, loading: false }))
    }
    const onDeletePermissionDialogConfirm = (e) => {
        e.preventDefault();

        const permission = deletePermissionState.permission;
        if(!permission) return;

        setDeletePermissionState(s => ({ ...s, loading: true }))

        deletePermission(permission.id).then(res => {
            if(res.status === 200)
            {
                setReloadTable(true);
                setDeletePermissionState(s => ({ ...s, resultState: formatSuccessMessage(res.data.message) }))
            }
            else
                setDeletePermissionState(s => ({ ...s, resultState: formatErrorMessage(res.data.message) }))
        }).catch(e => {
            console.error(e);
            setDeletePermissionState(s => ({ ...s, resultState: formatErrorMessage('Došlo je do greške prilikom brisanja korisnika!') }))
        });
    }
    const onCloseDeletePermissionDialog = (e) => {
        e.preventDefault();

        setDeletePermissionState(s => ({ ...s, isOpen: false, user: undefined, resultState: undefined, loading: false }))
    }

    const onCreateNewPermission = (e, parentPermissionRow) => {
        e.preventDefault();

        const text = `Unesite naziv nove ` + (parentPermissionRow ? `child permisije za '${parentPermissionRow?.name}' permisiju.` : 'permisije.')
        setCreatePermissionState(s => ({ ...s, open: true, parentId: parentPermissionRow?.id, text: text, resultState: undefined, loading: false}));
    }
    const onCreateNewPermissionModalSave = (permissionName) => {
        if(!permissionName) return;

        const data = {
            permissionName: permissionName,
            parentId: createPermissionState.parentId ?? null,
        }
        setCreatePermissionState(s => ({ ...s, loading: true}));
        createPermission(data).then(res => {
            if(res.status === 200)
                setReloadTable(true);
            setCreatePermissionState(s => ({ ...s, loading: false, resultState: formatMessage(res)}));
        }).catch(e => {
            console.error(e);
            setCreatePermissionState(s => ({ ...s, loading: false, resultState: formatErrorMessage('Došlo je do greške prilikom kreiranja permisije!')}));
        })
    }

    const onCreateNewPermissionModalClose = (e) => {
        e.preventDefault();

        setCreatePermissionState(s => ({ ...s, open: false, parent: undefined, text: '', resultState: undefined, loading: false }));
    } 

    const columns = [
        { 
            field: 'name', 
            headerName: 'Naziv permisije', 
            flex: 6, 
            minWidth: 100 
        },
        {
            field: 'actions',
            headerName: 'Akcije',
            flex: 2,
            minWidth: 200,
            renderCell: (params) => {
                const row = params.row;
                return (
                    <div>
                        {
                            row.parentId === null && (
                                <Button onClick={(e) => onCreateNewPermission(e, row)}><AddCircleOutlineIcon />Child</Button>
                            )
                        }
                        <Button onClick={(e) => onDeletePermissionClick(e, params.row)} color='error'><DeleteForeverIcon />Obriši</Button>
                    </div>
                )
            }
        },
    ];

    return (
        <>
            <SingleInputModal 
                title={'Kreiranje permisije'}
                text={createPermissionState.text}
                label='Naziv'
                open={createPermissionState.open}
                handleClose={onCreateNewPermissionModalClose}
                handleOk={onCreateNewPermissionModalSave}
                resultState={createPermissionState.resultState}
                loading={createPermissionState.loading}
            />
            <ConfirmModal 
                title={<div><DeleteForeverIcon />Brisanje permisije</div>}
                text={`Da li zaista želite da obrišete permisiju ${deletePermissionState.permission?.name}?`}
                open={deletePermissionState.isOpen}
                handleClose={onCloseDeletePermissionDialog}
                handleOk={onDeletePermissionDialogConfirm}
                resultState={deletePermissionState.resultState}
                loading={deletePermissionState.loading}
            />

            <PageTitle title='Permisije'/>
            <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: '8px',
                }} 
            >
                <Button variant='contained' onClick={onCreateNewPermission}><AddCircleOutlineIcon />Nova permisija</Button>
            </div >
            <CollapsibleTable 
                columns={columns}
                getData={getAllPermissions}
                loadData={true}
                reloadData={reloadTable}
                resetReloadData={() => setReloadTable(false)}
            />
        </>
    );
}

export default PermissionManagementPage;
