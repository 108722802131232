const Pages = {
    HOME_PAGE: '/',
    LOGIN: '/Login',
    REGISTER: '/Register',
    USER_MANAGEMENT: '/UserManagement',
    PERMISSION_MANAGEMENT: '/PermissionManagement',

    // Konfiguracija:
    CONFIGURATION: '/Configuration',

    // Rage
    RAGE_MONEY_PAGE: '/Rage/Money',
    RAGE_DONATOR_PAGE: '/Rage/Donator',
    RAGE_PLAYERS_PAGE: '/Rage/Players',
    RAGE_PROMOTIONS_PAGE: '/Rage/Promotions',
    RAGE_CEF_LOGS_PAGE: '/Rage/CefLogs',
    RAGE_CLIENT_LOGS_PAGE: '/Rage/ClientLogs',
    RAGE_CONN_LOGS_PAGE: '/Rage/Logs/ConnLog',
    RAGE_DEATH_LOGS_PAGE: '/Rage/Logs/DeathLog',
    RAGE_ARREST_LOGS_PAGE: '/Rage/Logs/ArrestLog',
    RAGE_FRACTION_LOGS_PAGE: '/Rage/Logs/FractionLog',
    RAGE_KUPOPRODAJA_LOGS_PAGE: '/Rage/Logs/KupoprodajaLog',
    RAGE_STOCK_LOGS_PAGE: '/Rage/Logs/StockLog',
    RAGE_STAFF_PAGE: '/Rage/Staff',
    RAGE_STAFF_LOGS_PAGE: '/Rage/Staff/Logs',
    RAGE_ORG_LAW_ENFORCEMENT_PAGE: '/Rage/Org/LawEnforcement',
    RAGE_ORG_CRIMINALS_PAGE: '/Rage/Org/Criminals',
    RAGE_BANS_PAGE: '/Rage/Bans',

    // Samp
    SAMP_S1_JOBS_PAGE: '/SampServerOne/Jobs',
    SAMP_S1_FAKE_ACCOUNTS_PAGE: '/SampServerOne/FakeAccounts',
    SAMP_S1_CHEATERS_PAGE: '/SampServerOne/Cheaters',
    SAMP_S1_CHANGE_NAMES_PAGE: '/SampServerOne/ChangeNames',

    SAMP_S2_JOBS_PAGE: '/SampServerTwo/Jobs',
    SAMP_S2_FAKE_ACCOUNTS_PAGE: '/SampServerTwo/FakeAccounts',
    SAMP_S2_CHEATERS_PAGE: '/SampServerTwo/Cheaters',
    SAMP_S2_CHANGE_NAMES_PAGE: '/SampServerTwo/ChangeNames',

    // Ostalo
    SOCIAL_NETWORKS_PAGE: '/SocialNetworks',
    ORDERS_PAGE: '/Orders',
}
 
export default Pages;