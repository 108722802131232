import React, { useEffect } from 'react';
import { useState } from 'react';
import PageTitle from '../../../components/common/PageTitle';
import { Grid } from '@mui/material';
import { getPlayerNamesChanges } from '../../../services/sampServices';
import DataTable from '../../../components/DataTable/DataTable';
import { formatDate } from '../../../helpers/timeFormatter';
import { useLocation } from 'react-router-dom';

const SampChangeNamesPage = ({
    serverNum,
}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        getPlayerNamesChanges(serverNum).then(res => {
            if(res.status === 200)
                setData(res.data);
        }).catch(e => {
            console.error(e);
        }).finally(() => {
            setLoading(false);
        })
    }, [serverNum]);

    const changeNameColumns = [
        { field: 'type', headerName: 'Tip', flex: 1, minWidth: 60 },
        { field: 'previousName', headerName: 'Prethodno ime', flex: 1, minWidth: 100 },
        { field: 'newName', headerName: 'Novo ime', flex: 1, minWidth: 100 },
        { field: 'admin', headerName: 'Admin', flex: 1, minWidth: 100 },
        { field: 'date', headerName: 'Datum', type: 'date', flex: 1, minWidth: 100, valueFormatter: (params) => formatDate(params.value) },
    ];

    return ( 
        <>
            <PageTitle title='Promene imena'/>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <DataTable 
                        title={'Promene imena'}
                        columns={changeNameColumns}
                        staticData={data}
                        density={'compact'}
                        loading={loading}
                        getRowId={(row) => row.date}
                        initialSort={{
                            field: 'date',
                            sort: 'desc'
                        }}
                    />
                </Grid>
            </Grid>
        </> 
    );
}
 
export default SampChangeNamesPage;