import { Outlet, Link } from "react-router-dom";
import './Layout.scss';
import TopBar from './TopBar/TopBar';
import Footer from './Footer/Footer';
import Sidebar from './Sidebar/Sidebar';
import AppOLD from '../../assets/js/app';

const Layout = () =>
{
	return (
		<div className="main-container" data-sidebar="dark">
			<AppOLD />
			<div id="preloader">
				<div id="status">
					<div className="spinner-chase">
						<div className="chase-dot"></div>
						<div className="chase-dot"></div>
						<div className="chase-dot"></div>
						<div className="chase-dot"></div>
						<div className="chase-dot"></div>
					</div>
				</div>
			</div>

			<div id="layout-wrapper" className="layout-container">
				<TopBar />
				<Sidebar />

				<div className="main-content">
					<div className="page-content">
						<div className="container-fluid">
							{/* @await Html.PartialAsync("~/Views/_Shared/_page_title.cshtml") */}
							
							<Outlet />
						</div>
					</div>

					<Footer />
				</div>
			</div>
		</div>
	)
};

export default Layout;