import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';

const Section = ({
    title,
    defaultExpanded,
    children
}) => {
    return (  
        <Accordion defaultExpanded={defaultExpanded}>
            <AccordionSummary
                style={{ backgroundColor: '#32394E'}}
                expandIcon={<ExpandMoreIcon />}
            >
                <Typography>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: '#2A3042'}}>
                {children}
            </AccordionDetails>
        </Accordion>
    );
}

Section.propTypes = {
    title: PropTypes.string,
    defaultExpanded: PropTypes.bool,
}
 
export default Section;