import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import RenderRow from './RenderRow';

const CollapsibleTable = ({
    columns,
    loadData,
    getData,
    reloadData,
    resetReloadData,
}) => {
    const [data, setData] = useState([]);
    const [tableInited, setTableInited] = useState(false);

    useEffect(() => {
        if(getData && ((loadData && !tableInited) || reloadData))
        {
            getData().then(res => {
                if(res.status === 200)
                    setData(res.data);
            }).catch(e => {
                console.error(e);
            });

            if(!tableInited)
                setTableInited(true);

            if(reloadData)
                resetReloadData();
        }
    }, [loadData, reloadData]);

    const depthData = {
        depth: 0,
    }


    return (  
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table" style={{border: '1px solid rgba(81, 81, 81, 1)', borderBottom: '0px'}}>
                <TableHead>
                    <TableRow style={{display: 'flex', backgroundColor: '#32394e'}}>
                        {
                            columns.map((column, index) => {
                                return (
                                    <TableCell 
                                        key={index}
                                        className={column.headerClassName}
                                        style={{
                                            display: 'flex',
                                            justifyContent: column.headerAlign ?? 'left',
                                            flex: column.flex ?? 1,
                                            minWidth: column.minWidth ?? 0,
                                            fontWeight: 600,
                                            borderRight: '1px solid rgba(81, 81, 81, 1)',
                                        }}
                                    >
                                        <div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                            {
                                                column.renderHeader ? column.renderHeader(column) : column.headerName
                                            }
                                        </div>
                                    </TableCell> 
                                )
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, index) => (
                        <RenderRow key={index} row={row} columns={columns} depthData={depthData} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
 
export default CollapsibleTable;