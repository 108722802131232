import { Grid } from '@mui/material';
import DataTable from '../../../components/DataTable/DataTable';
import PageTitle from '../../../components/common/PageTitle';
import { formatDate } from '../../../helpers/timeFormatter';
import { getPromotionsByDays, getTopPromotersWeekly, getTopPromotions } from '../../../services/rageServices';
import { formatNumber } from '../../../helpers/numberFormatter';
import { formatMoney } from '../../../helpers/moneyFormatter';
import ColumnChart from '../../../components/Charts/ColumnChart';
import TopPromoterWeeklyChartHeader from './Headers/TopPromoterWeeklyChartHeader';
import { useState } from 'react';

const RagePromotionsPage = () => {

    const [topPromoterFilters, setTopPromoterFilters] = useState({ days: 180, topPromoters: 5 });

    const promoCodesWithMostReferrals = [
        { field: 'username', headerName: 'Korisničko ime', flex: 1, minWidth: 100 },
        { field: 'code', headerName: 'Kod', flex: 1, minWidth: 100 },
        { field: 'registered', headerName: 'Broj registracija', type: 'number', flex: 1, minWidth: 100, valueFormatter: (params) => formatNumber(params.value) },
        { field: 'rewarded', headerName: 'Nagrađeno', type: 'number', flex: 1, minWidth: 100, valueFormatter: (params) => formatNumber(params.value) },
        { field: 'rewardedPercent', headerName: 'Procenat nagrađenih', type: 'number', flex: 1, minWidth: 100, valueFormatter: (params) => formatNumber(params.value, 1, 1) },
        { field: 'moneySum', headerName: 'Novac', type: 'number', flex: 1, minWidth: 100, valueFormatter: (params) => formatMoney(params.value) },
        { field: 'bscSum', headerName: 'BSC', type: 'number', flex: 1, minWidth: 100, valueFormatter: (params) => formatNumber(params.value) },
        { field: 'dateCreated', headerName: 'Datum kreiranja koda', type: 'date', flex: 1, minWidth: 100, valueFormatter: (params) => formatDate(params.value) },
    ];

    return ( 
        <>
            <PageTitle title='RageMP - Promocije'/>

            <Grid container justifyContent={'center'} spacing={2} marginBottom={'15px'}>
                <Grid item container justifyContent={'center'} spacing={2}>
                    <Grid item xs={12}>
                        <DataTable 
                            title={'TOP promoteri'}
                            columns={promoCodesWithMostReferrals}
                            getData={getTopPromotions}
                            loadData={true}
                            density={'compact'}
                            getRowId={(row) => row.id}
                            initialSort={{
                                field: 'registered',
                                sort: 'desc'
                            }}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <ColumnChart
                            title={'Ukupno aktiviranih promo kodova'}
                            getData={getPromotionsByDays}
                            loadData={true}
                            seriesName={'Promocije'}
                            type='datetime'
                            tooltip={{x: { format: 'dd. MMM yyyy.' }}}
                            isStacked={true}
                            height={500}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <ColumnChart
                            header={<TopPromoterWeeklyChartHeader filters={topPromoterFilters} setFilters={setTopPromoterFilters} />}
                            title={'Top promoteri na nedeljnom nivou'}
                            getData={getTopPromotersWeekly}
                            loadData={true}
                            seriesName={'Promocije'}
                            isStacked={true}
                            height={650}
                            type='category'
                            filters={topPromoterFilters}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </> 
    );
}
 
export default RagePromotionsPage;