import { Grid } from '@mui/material';
import DataTable from '../../../components/DataTable/DataTable';
import PageTitle from '../../../components/common/PageTitle';
import { formatDateTime } from '../../../helpers/timeFormatter';
import { getPDCallLogs, getTicketLogs } from '../../../services/rageServices';
import { formatMoney } from '../../../helpers/moneyFormatter';

const RageOrgLawEnforcementPage = () => {

    const pdCallsColumns = [
        { field: 'id', headerName: 'Id', flex: 1, minWidth: 100, hide: true },
        { field: 'uuid', headerName: 'UUID', flex: 1, minWidth: 80 },
        { field: 'name', headerName: 'Ime pozivaoca', flex: 1, minWidth: 200 },
        { field: 'reason', headerName: 'Razlog poziva', flex: 4, minWidth: 400 },
        { field: 'date', headerName: 'Datum', type: 'date', flex: 1, minWidth: 150, valueFormatter: (params) => formatDateTime(params.value) },
    ];

    const ticketColumns = [
        { field: 'id', headerName: 'Id', flex: 1, minWidth: 100, hide: true },
        { field: 'policemanUuid', headerName: 'UUID policajca', flex: 1, minWidth: 120 },
        { field: 'policemanName', headerName: 'Ime policajca', flex: 1, minWidth: 200 },
        { field: 'targetUuid', headerName: 'UUID igrača', flex: 1, minWidth: 80 },
        { field: 'targetName', headerName: 'Ime igrača', flex: 1, minWidth: 200 },
        { field: 'sum', headerName: 'Kazna', flex: 1, minWidth: 100, type: 'number', valueFormatter: (params) => formatMoney(params.value) },
        { field: 'reason', headerName: 'Razlog', flex: 3, minWidth: 400 },
        { field: 'date', headerName: 'Datum', type: 'date', flex: 1, minWidth: 150, valueFormatter: (params) => formatDateTime(params.value) },
    ];

    return ( 
        <>
            <PageTitle title='RageMP - Organi reda'/>

            <Grid container justifyContent={'center'} spacing={2} marginBottom={'15px'}>
                <Grid item container justifyContent={'center'} spacing={2}>
                    <Grid item xs={12}>
                        <DataTable 
                            title={'PD pozivi'}
                            columns={pdCallsColumns}
                            getData={getPDCallLogs}
                            loadData={true}
                            density={'compact'}
                            getRowId={(row) => row.id}
                            initialSort={{
                                field: 'date',
                                sort: 'desc'
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DataTable 
                            title={'Tiketi'}
                            columns={ticketColumns}
                            getData={getTicketLogs}
                            loadData={true}
                            density={'compact'}
                            getRowId={(row) => row.id}
                            initialSort={{
                                field: 'date',
                                sort: 'desc'
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </> 
    );
}
 
export default RageOrgLawEnforcementPage;