import PageTitle from '../../../components/common/PageTitle';
import ProblemLogsPage from './ProblemLogsPage';

const RageCefLogsPage = () => {
    return ( 
        <>
            <PageTitle title='RageMP - CEF Logs'/>

            <ProblemLogsPage 
                pageId={2}
                tableTitle={'CEF Logs'}
            />
        </> 
    );
}
 
export default RageCefLogsPage;