import React, { useState } from 'react';
import './UserManagementPage.scss'
import PageTitle from '../../components/common/PageTitle';
import DataTable from '../../components/DataTable/DataTable';
import { Button } from '@mui/material';
import { deleteUser, getAllRolesWithUserHasFlag, getUsers } from '../../services/userManagement';
import ChangeUserRolesModal from '../../components/Modals/ChangeUserRolesModal';
import UserActiveSwitch from '../../components/Switches/components/UserActiveSwitch';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ConfirmModal from '../../components/Abstracts/Modals/ConfirmModal';
import { formatErrorMessage, formatSuccessMessage } from '../../components/Alerts/AlertBuilder';
import { formatDateTime } from '../../helpers/timeFormatter';


const UserManagementPage = () => {
    const [configUserRoles, setConfigUserRoles] = useState({ isOpen: false, user: undefined });
    const [deleteUserState, setDeleteUserState] = useState({ isOpen: false, user: undefined, resultState: undefined, loading: false });
    const [reloadTable, setReloadTable] = useState(false);

    const onDeleteUserClick = (e, user) => {
        e.preventDefault();

        setDeleteUserState(s => ({ ...s, isOpen: true, user: user, resultState: undefined, loading: false }))
    }

    const onDeleteUserDialogConfirm = (e) => {
        e.preventDefault();

        const user = deleteUserState.user;
        if(!user) return;

        setDeleteUserState(s => ({ ...s, loading: true }))

        deleteUser(user.id).then(res => {
            if(res.status === 200)
            {
                setReloadTable(true);
                setDeleteUserState(s => ({ ...s, resultState: formatSuccessMessage(res.data.message) }))
            }
            else
                setDeleteUserState(s => ({ ...s, resultState: formatErrorMessage(res.data.message) }))
        }).catch(e => {
            console.error(e);
            setDeleteUserState(s => ({ ...s, resultState: formatErrorMessage('Došlo je do greške prilikom brisanja korisnika!') }))
        });
    }

    const onCloseDeleteUserDialog = (e) => {
        e.preventDefault();

        setDeleteUserState(s => ({ ...s, isOpen: false, user: undefined, resultState: undefined, loading: false }))
    }

    const onPermissionConfigClick = (e, user) => {
        e.preventDefault();

        getAllRolesWithUserHasFlag(user.id).then(res => {
            if(res.status === 200)
            {
                user.roles = res.data;
                setConfigUserRoles({isOpen: true, user: user});
            }
        }).catch(e => {
            console.error(e);
        })
    }

    const columns = [
        { field: 'id', headerName: 'ID' },
        { field: 'username', headerName: 'Ime', flex: 1.5, minWidth: 100 },
        { field: 'email', headerName: 'Email', flex: 2, minWidth: 200 },
        { 
            field: 'permissions', 
            headerName: 'Permisije', 
            flex: 2, 
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <div style={{ paddingLeft: '20px'}}>
                        <ul>
                            {
                                params.row.permissions && params.row.permissions.map((permission, index) => {
                                    return <li key={index}>{permission}</li>
                                })
                            }
                        </ul>
                    </div>
                )
            }
        },
        { field: 'lastLogin', headerName: 'Poslednji Login', type: 'date', flex: 1, minWidth: 150, valueFormatter: (params) => formatDateTime(params.value) },
        {
            field: 'role',
            headerName: 'Nalog aktivan',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            minWidth: 80,
            renderCell: (params) => {
                return (
                    <UserActiveSwitch 
                        user={params.row}
                    />
                )
            }
        },
        {
            field: 'actions',
            headerName: 'Akcije',
            sortable: false,
            flex: 3,
            minWidth: 300,
            renderCell: (params) => {
                return (
                <>
                    <Button onClick={(e) => onPermissionConfigClick(e, params.row)}><FactCheckIcon />Permisije</Button>
                    <Button onClick={(e) => onDeleteUserClick(e, params.row)} color='error'><DeleteForeverIcon />Obriši</Button>
                </>)
            }
        },
    ];

    return (
        <>
            <PageTitle title='Lista korisnika'/>
            <ChangeUserRolesModal 
                open={configUserRoles.isOpen}
                user={configUserRoles.user}
                handleClose={() => setConfigUserRoles(s => ({ ...s, isOpen: false, user: undefined}))}
            />
            <ConfirmModal 
                title={<div><DeleteForeverIcon />Brisanje korisnika</div>}
                text={`Da li zaista želite da obrišete korisnika ${deleteUserState.user?.username}?`}
                open={deleteUserState.isOpen}
                handleClose={onCloseDeleteUserDialog}
                handleOk={onDeleteUserDialogConfirm}
                resultState={deleteUserState.resultState}
                loading={deleteUserState.loading}
            />
            <DataTable 
                title={'Lista svih korisnika'}
                description={'Prikazana je lista svih korisnika.'}
                columns={columns}
                getData={getUsers}
                loadData={true}
                reloadData={reloadTable}
                resetReloadData={() => setReloadTable(false)}
                getRowHeight={() => 'auto'}
            />
        </>
    );
}

export default UserManagementPage;
