import React, { useEffect, useState } from 'react';
import CardWithLoader from '../Cards/CardWithLoader';
import FormAbstract from '../Abstracts/FormAbstract';
import InputTextField from '../FormParts/InputTextField';
import useNotification from '../common/useNotification';
import PropTypes from 'prop-types';
import { checkDbConnectivity } from '../../services/configuration';
import InputPassword from '../FormParts/InputPassword';

const MySQLConfigForm = ({
    title,
    pageLoading,
    dataKey,
    data,
    handleSetData
}) => {
    const [loading, setLoading] = useState(false);
    const { showError, showSuccess } = useNotification();

    const onSave = (data) => {
        setLoading(true);
        data.dbType = dataKey[0].toUpperCase() + dataKey.slice(1);

        checkDbConnectivity(data).then(res => {
            if(res.status === 200)
            {
                handleSetData(s => ({ ...s, [dataKey]: res.data}));
                showSuccess(`[${title}] Konfiguracija uspešno sačuvana!`);
            }
            else
                showError(`[${title}] ${res.data.message}`);
        }).catch(e => {
            console.error(e);
            showError(`[${title}] Došlo je do greške prilikom čuvanja konfiguracije!`);
        }).finally(() => {
            setLoading(false);
        })
    }

    return (  
        <CardWithLoader
            header={title}
            contentLoading={pageLoading}
        >
            <FormAbstract
                handleSave={onSave}
                buttonText='Save'
                buttonProps={{
                    loading: loading,
                }}
                initialState={data[dataKey]}
            >
                <InputTextField
                    id="host"
                    label='Host'
                    fullWidth
                    required
                />

                <InputTextField
                    id="username"
                    label='Username'
                    fullWidth
                    required
                />

                <InputPassword
                    id="password"
                    label='Password'
                    fullWidth
                    required
                />

                <InputTextField
                    id="databaseName"
                    label='Database'
                    fullWidth
                    required
                />
            </FormAbstract>
        </CardWithLoader>
    );
}

MySQLConfigForm.propTypes = {
    title: PropTypes.string,
    saveData: PropTypes.func,
    pageLoading: PropTypes.bool,
}
 
export default MySQLConfigForm;