import React, { useEffect, useState } from 'react';
import CardWithLoader from './CardWithLoader';
import PropTypes from 'prop-types'
import { IconButton } from '@mui/material';
import useNotification from '../common/useNotification';


const ServerInfoCard = ({ 
    title, 
    apiForDataRequest
}) => {
    const [loadingInfo, setLoadingInfo] = useState(false);
    const [serverData, setServerData] = useState({ status: 'Offline', hostname: '/', players: 0, maxPlayers: 0 });
    const { showError } = useNotification();


    const onLoadInfoClick = (e) => {
        e.preventDefault();

        loadServerInfo();
    }
    const loadServerInfo = () => {
        setLoadingInfo(true);
        apiForDataRequest().then(res => {
            setLoadingInfo(false);
            if(res.status === 200)
                setServerData(res.data);
            else
                showError(res.data.message);
        }).catch(e => {
            setLoadingInfo(false);
            console.error(e);
            showError('Došlo je do greške prilikom pribavljanja podataka!');
        });
    }

    useEffect(() => {
        loadServerInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getColorByPlayersNum = (playersNum, maxPlayers) => {
        if(playersNum <= 0.4*maxPlayers) return '#f46a6a'
        else if(playersNum >= 0.7*maxPlayers) return '#34C38F'

        return undefined;
    }

    const getStatusColor = (status) => (status === "Online" ? '#34c38f' : '#f46a6a');

    return (  
        <CardWithLoader 
            header={(
            <>{title}
            <IconButton 
                onClick={onLoadInfoClick}
                size={'small'} 
                sx={{
                    padding: '0px', 
                    marginLeft: '8px', 
                    opacity: '.7'
                }}
                disabled={loadingInfo}
            >
                <i className="mdi mdi-update"/>
            </IconButton></>)}
            contentLoading={loadingInfo}
            fullHeight
        >
            <table>
                <tbody style={{textAlign: 'right', fontSize: '1rem', alignItems: 'center', height: '100%'}}>
                    <tr>
                        <td style={{fontWeight: 600}}>Status:</td>
                        <td style={{fontWeight: 300, textAlign: 'left', paddingLeft: '10px', color: getStatusColor(serverData.status)}}>{serverData.status}</td>
                    </tr>
                    <tr>
                        <td style={{fontWeight: 600}}>Hostname:</td>
                        <td style={{fontWeight: 300, textAlign: 'left', paddingLeft: '10px'}}>{serverData.hostname}</td>
                    </tr>
                    <tr>
                        <td style={{fontWeight: 600}}>Players:</td>
                        <td style={{fontWeight: 300, textAlign: 'left', paddingLeft: '10px', color: getColorByPlayersNum(serverData.players, serverData.maxPlayers)}}>
                            {serverData.players}/{serverData.maxPlayers}
                        </td>
                    </tr>
                </tbody>
            </table>
        </CardWithLoader>
    );
}

ServerInfoCard.propTypes = {
    title: PropTypes.string,
    apiForDataRequest: PropTypes.func.isRequired
}
 
export default ServerInfoCard;