import { useState } from 'react';
import PageTitle from '../../../../components/common/PageTitle';
import DataTable from '../../../../components/DataTable/DataTable';
import { formatDateTimeWithSeconds } from '../../../../helpers/timeFormatter';
import { getRageLogTable } from '../../../../services/rageServices';
import { RAGE_LOG_TABLE } from '../../../../constants/rageLogTables';

const RageFractionLog = () => {

    const [reload, setReload] = useState(false);

    const tableColumns = [
        { field: 'id', headerName: 'Id', flex: 1, minWidth: 90, hide: true },
        { field: 'fractionId', headerName: 'Org Id', flex: 0.5, minWidth: 100 },
        { field: 'fractionName', headerName: 'Org Ime', flex: 1.75, minWidth: 250 },
        { field: 'player', headerName: 'Player', flex: 1.25, minWidth: 180 },
        { field: 'action', headerName: 'Action', flex: 3, minWidth: 300 },
        { field: 'target', headerName: 'Target', flex: 1.25, minWidth: 180 },
        { field: 'date', headerName: 'Datum', type: 'date', flex: 1.25, minWidth: 165, valueFormatter: (params) => formatDateTimeWithSeconds(params.value) },
    ];

    return ( 
        <>
            <PageTitle title='RageMP - Fraction Logs'/>

            <DataTable 
                title={'Logovi organizacije - poslednja 15 dana'}
                columns={tableColumns}
                getData={() => getRageLogTable(RAGE_LOG_TABLE.FractionLog)}
                loadData={true}
                getRowId={(row) => row.id}
                initialSort={{
                    field: 'date',
                    sort: 'desc'
                }}
                reloadData={reload}
                resetReloadData={() => setReload(false)}
            />
        </> 
    );
}
 
export default RageFractionLog;