import React, { useEffect, useState } from 'react';
import PageTitle from '../../components/common/PageTitle';
import { getPanelConfiguration } from '../../services/configuration';
import ToggleRegistrationsSwitch from './PanelConfig/ToggleRegistrationsSwitch';
import Section from '../../components/Sections/Section';
import SampConfigSection from './SampConfig/SampConfigSection';
import RageConfigSection from './RageConfig/RageConfigPage';

const ConfigurationPage = () => {
    const [data, setData] = useState({ isRegistrationEnabled: false });
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        setLoading(true);
        getPanelConfiguration().then(res => {
            if(res.status === 200)
                setData(res.data);
        }).catch(e => {
            console.error(e);
        }).finally(() => {
            setLoading(false);
        });

    }, []);

    return (  
        <>
            <PageTitle title='Konfiguracija'/>

            <Section title={'Generalno'} defaultExpanded >
                <ToggleRegistrationsSwitch 
                    isRegistrationActive={data.isRegistrationEnabled}
                    handleRegistrationsActive={(checked) => setData(s => ({ ...s, isRegistrationEnabled: checked }))}
                    pageLoading={loading}
                />
            </Section>

            <Section title={'RageMP konfiguracija'} defaultExpanded>
                <RageConfigSection />
            </Section>
            
            <Section title={'SAMP konfiguracija'} defaultExpanded>
                <SampConfigSection />
            </Section>
        </>
    );
}
 
export default ConfigurationPage;