import { useState } from 'react';
import PageTitle from '../../../../components/common/PageTitle';
import DataTable from '../../../../components/DataTable/DataTable';
import { formatDateTimeWithSeconds } from '../../../../helpers/timeFormatter';
import { getRageLogTable } from '../../../../services/rageServices';
import { RAGE_LOG_TABLE } from '../../../../constants/rageLogTables';

const RageArrestLogPage = () => {

    const [reload, setReload] = useState(false);

    const tableColumns = [
        { field: 'id', headerName: 'Id', flex: 1, minWidth: 90, hide: true },
        { field: 'playerUuid', headerName: 'UUID pol.', flex: 0.5, minWidth: 90 },
        { field: 'playerName', headerName: 'Ime policajca', flex: 1.25, minWidth: 180 },
        { field: 'targetUuid', headerName: 'UUID uhap.', flex: 0.5, minWidth: 90 },
        { field: 'targetName', headerName: 'Ime uhapšenog', flex: 1.25, minWidth: 180 },
        { field: 'minutes', headerName: 'Minuti', type: 'number', flex: 0.75, minWidth: 100 },
        { field: 'reason', headerName: 'Razlog', flex: 2, minWidth: 200 },
        { field: 'date', headerName: 'Datum', type: 'date', flex: 1.25, minWidth: 165, valueFormatter: (params) => formatDateTimeWithSeconds(params.value) },
    ];

    return ( 
        <>
            <PageTitle title='RageMP - Arrest Logs'/>

            <DataTable 
                title={'Hapšenja - poslednja 30 dana'}
                columns={tableColumns}
                getData={() => getRageLogTable(RAGE_LOG_TABLE.ArrestLog)}
                loadData={true}
                getRowId={(row) => row.id}
                initialSort={{
                    field: 'date',
                    sort: 'desc'
                }}
                reloadData={reload}
                resetReloadData={() => setReload(false)}
            />
        </> 
    );
}
 
export default RageArrestLogPage;