import React, { useEffect } from 'react';
import { useState } from 'react';
import PageTitle from '../../../components/common/PageTitle';
import { Grid } from '@mui/material';
import { getCheaters } from '../../../services/sampServices';
import DataTable from '../../../components/DataTable/DataTable';
import { formatDate } from '../../../helpers/timeFormatter';

const SampCheatersPage = ({
    serverNum,
}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {

        setLoading(true);
        getCheaters(serverNum).then(res => {
            if(res.status === 200)
                setData(res.data);
        }).catch(e => {
            console.error(e);
        }).finally(() => {
            setLoading(false);
        })
    }, [serverNum]);

    const cheatersColumns = [
        { field: 'name', headerName: 'Ime', flex: 1, minWidth: 100 },
        { field: 'playtimeHours', headerName: 'Sati u igri', type: 'number', flex: 1, minWidth: 80  },
        { field: 'registered', headerName: 'Registracija', type: 'date', flex: 1, minWidth: 100, valueFormatter: (params) => formatDate(params.value) },
        { field: 'reportsNum', headerName: 'Broj reportova', type: 'number', flex: 1, minWidth: 120 },
    ];

    return ( 
        <>
            <PageTitle title='Čiteri'/>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <DataTable 
                        title={'Najviše reportovani igrači za čit (7 dana)'}
                        columns={cheatersColumns}
                        staticData={data}
                        density={'compact'}
                        loading={loading}
                        getRowId={(row) => row.name}
                        initialSort={{
                            field: 'reportsNum',
                            sort: 'desc'
                        }}
                    />
                </Grid>
            </Grid>
        </> 
    );
}
 
export default SampCheatersPage;