import React from 'react';
import DataTable from '../../../components/DataTable/DataTable';
import { Grid } from '@mui/material';
import { formatDate } from '../../../helpers/timeFormatter';
import { formatMoney } from '../../../helpers/moneyFormatter';


const JobsPerDaysTables = ({
    dataForToday,
    dataFor7Days,
    loading
}) => {

    const columns = [
        { field: 'name', headerName: 'Ime', flex: 1, minWidth: 100, valueGetter: (params) => params.row.playerData.name },
        { field: 'playtimeHours', headerName: 'Sati u igri', type: 'number', flex: 1, minWidth: 80, valueGetter: (params) => params.row.playerData.playtimeHours  },
        { field: 'registered', headerName: 'Registracija', type: 'date', flex: 1, minWidth: 100, valueGetter: (params) => params.row.playerData.registered,valueFormatter: (params) => formatDate(params.value) },
        { field: 'money', headerName: 'Zarađeni novac', type: 'number', flex: 1, minWidth: 120, valueFormatter: (params) => formatMoney(params.value)  },
        { field: 'completedRounds', headerName: 'Odrađeno tura', type: 'number', flex: 1, minWidth: 120  },
    ];

    return (  
        <Grid item container spacing={2}>
            <Grid item xs={12} lg={6}>
                <DataTable 
                    title={'Poslovi (danas)'}
                    columns={columns}
                    staticData={dataForToday}
                    density={'compact'}
                    loading={loading}
                    getRowId={(row) => row.playerData.name}
                    initialSort={{
                        field: 'money',
                        sort: 'desc'
                    }}
                />
            </Grid>

            <Grid item xs={12} lg={6}>
                <DataTable 
                    title={'Poslovi (7 dana)'}
                    columns={columns}
                    staticData={dataFor7Days}
                    density={'compact'}
                    loading={loading}
                    getRowId={(row) => row.playerData.name}
                    initialSort={{
                        field: 'money',
                        sort: 'desc'
                    }}
                />
            </Grid>  
        </Grid>
    );
}
 
export default JobsPerDaysTables;