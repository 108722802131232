import React, { useState } from 'react';
import Android12Switch from '../../../components/Switches/Android12Switch';
import { switchRegistrationsState } from '../../../services/configuration';
import { FormControlLabel, Typography } from '@mui/material';
import useNotification from '../../../components/common/useNotification';

const ToggleRegistrationsSwitch = ({
    isRegistrationActive,
    handleRegistrationsActive,
    pageLoading,
}) => {
    const [loading, setLoading] = useState(false);
    const { showSuccess, showError } = useNotification();

    const onRegistrationSwitchState = (e) => {
        setLoading(true);
        switchRegistrationsState().then(res => {
            if(res.status === 200)
            {
                const isActive = res.data;
                handleRegistrationsActive(isActive);
                showSuccess(<Typography>Uspešno <strong style={{ color: isActive ? '#00FF00' : '#FF0000'}}>{(isActive ? "aktivirana" : "deaktivirana")}</strong> registracija novih korisnika!</Typography>)
            }
        }).catch(e => {
            console.error(e);
            showError('Došlo je do greške prilikom promene statusa registracija!');
        }).finally(() => {
            setLoading(false);
        });
    }

    return (  
        <FormControlLabel 
            control={
            <Android12Switch 
                onChange={onRegistrationSwitchState}
                checked={isRegistrationActive}
                disabled={loading || pageLoading}
            />}
            label={<Typography color={isRegistrationActive ? 'primary' : 'error'}>Registracija novih korisnika</Typography >}
            //label={'Registracija novih korisnika'}
        />
    );
}
 
export default ToggleRegistrationsSwitch;