import { Grid } from '@mui/material';
import PageTitle from '../../../components/common/PageTitle';
import DataTable from '../../../components/DataTable/DataTable';
import { formatDateTime } from '../../../helpers/timeFormatter';
import { getBankRobberyLogs } from '../../../services/rageServices';
import { formatMoney } from '../../../helpers/moneyFormatter';

const RageOrgCriminalsPage = () => {

    const pdCallsColumns = [
        { field: 'id', headerName: 'Id', flex: 1, minWidth: 100, hide: true },
        { field: 'uuid', headerName: 'UUID', flex: 1, minWidth: 80 },
        { field: 'name', headerName: 'Ime igrača', flex: 2, minWidth: 200 },
        { field: 'fractionId', headerName: 'Id organizacije', flex: 0.5, minWidth: 120, type: 'number' },
        { field: 'fractionName', headerName: 'Ime organizacije', flex: 2, minWidth: 300 },
        { field: 'type', headerName: 'Tip', flex: 3, minWidth: 250 },
        { field: 'amount', headerName: 'Količina', flex: 1, minWidth: 200, type: 'number', valueFormatter: (params) => formatMoney(params.value) },
        { field: 'date', headerName: 'Datum', type: 'date', flex: 1, minWidth: 150, valueFormatter: (params) => formatDateTime(params.value) },
    ];

    return ( 
        <>
            <PageTitle title='RageMP - Kriminalne organizacije'/>

            <Grid container justifyContent={'center'} spacing={2} marginBottom={'15px'}>
                <Grid item container justifyContent={'center'} spacing={2}>
                    <Grid item xs={12}>
                        <DataTable 
                            title={'Pljačka banke'}
                            columns={pdCallsColumns}
                            getData={getBankRobberyLogs}
                            loadData={true}
                            density={'compact'}
                            getRowId={(row) => row.id}
                            initialSort={{
                                field: 'date',
                                sort: 'desc'
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </> 
    );
}
 
export default RageOrgCriminalsPage;