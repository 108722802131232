import { useState } from 'react';
import PageTitle from '../../../../components/common/PageTitle';
import DataTable from '../../../../components/DataTable/DataTable';
import { formatDateTimeWithSeconds } from '../../../../helpers/timeFormatter';
import { getRageLogTable } from '../../../../services/rageServices';
import { RAGE_LOG_TABLE } from '../../../../constants/rageLogTables';
import { formatMoney } from '../../../../helpers/moneyFormatter';

const RageKupoprodajaLogPage = () => {

    const [reload, setReload] = useState(false);

    const tableColumns = [
        { field: 'id', headerName: 'Id', flex: 1, minWidth: 90, hide: true },
        { field: 'type', headerName: 'Vrsta imovine', flex: 0.75, minWidth: 120 },
        { field: 'propertyId', headerName: 'Id imovine', type: 'number', flex: 0.75, minWidth: 100, valueFormatter: (params) => params.value },
        { field: 'sellerUuid', headerName: 'UUID prodavca', flex: 0.5, minWidth: 140 },
        { field: 'sellerName', headerName: 'Ime prodavca', flex: 1.25, minWidth: 180 },
        { field: 'buyerUuid', headerName: 'UUID kupca', flex: 0.5, minWidth: 140 },
        { field: 'buyerName', headerName: 'Ime kupca', flex: 1.25, minWidth: 180 },
        { field: 'price', headerName: 'Cena', type: 'number', flex: 1, minWidth: 130, valueFormatter: (params) => formatMoney(params.value) },
        { field: 'date', headerName: 'Datum', type: 'date', flex: 1.25, minWidth: 165, valueFormatter: (params) => formatDateTimeWithSeconds(params.value) },
    ];

    return ( 
        <>
            <PageTitle title='RageMP - Kupoprodaja Logs'/>

            <DataTable 
                title={'Kupoprodaja - poslednja 30 dana'}
                columns={tableColumns}
                getData={() => getRageLogTable(RAGE_LOG_TABLE.KupoprodajaLog)}
                loadData={true}
                getRowId={(row) => row.id}
                initialSort={{
                    field: 'date',
                    sort: 'desc'
                }}
                reloadData={reload}
                resetReloadData={() => setReload(false)}
            />
        </> 
    );
}
 
export default RageKupoprodajaLogPage;