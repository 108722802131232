import React from 'react';
import PermissionContext from './PermissionContext';
import { getUserPermissions } from '../../services/home';

let lastUpdate = null;
let fetchAwaitable = null;
let permissionsCache = [];

const getPermissions = async () => {
    const currDate = new Date().getTime();
    if(!lastUpdate || currDate >= lastUpdate)
    {
        if(!fetchAwaitable)
        {
            fetchAwaitable = getUserPermissions();
            const res = await fetchAwaitable;
            permissionsCache = res.data;
            fetchAwaitable = null;
            lastUpdate = new Date().getTime() + 10;
        }
        else
            await fetchAwaitable;

    }

    return permissionsCache;
}

const PermissionProvider = ({children}) => {

    const isAllowedTo = async (permission) => {
        
        const permissions = await getPermissions();
        return permissions.includes(permission);
    };

    return <PermissionContext.Provider value={{isAllowedTo}}>{children}</PermissionContext.Provider>;
}
 
export default PermissionProvider;