import { Box, Typography } from '@mui/material';
import React from 'react';

const CustomCard = ({
    title,
    renderTitle,
    children,
    fullHeight
}) => {
    return (  
        <>
            <Box 
                display={'flex'} 
                flexDirection={'column'} 
                backgroundColor={'#2a3042'} 
                border={'1px solid black'}
                borderRadius={'5px'}
                height={fullHeight ? '100%' : null}
            >
                {
                    title && (renderTitle ? renderTitle(title) : (
                        <Typography variant='h6' align='center' padding='1rem 0' backgroundColor='#32394e' borderRadius={'5px 5px 0 0'}>{title}</Typography>
                    ))
                }
                <Box padding={'1.25rem'}>
                    {children}
                </Box>
            </Box>
        </>     
    );
}
 
export default CustomCard;