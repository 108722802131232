import { Grid } from '@mui/material';
import DataTable from '../../../components/DataTable/DataTable';
import PageTitle from '../../../components/common/PageTitle';
import { formatDateTime } from '../../../helpers/timeFormatter';
import { getPunishments, getReports } from '../../../services/rageServices';

const RageStaffLogsPage = () => {

    const punishmentColumns = [
        { field: 'playerName', headerName: 'Ime igrača', flex: 1, minWidth: 150 },
        { field: 'punishment', headerName: 'Kazna', flex: 1, minWidth: 100 },
        { field: 'time', headerName: 'Vreme kazne', flex: 1, minWidth: 100 },
        { field: 'reason', headerName: 'Razlog', flex: 1.5, minWidth: 200},
        { field: 'admin', headerName: 'Admin', flex: 1, minWidth: 100 },
        { field: 'date', headerName: 'Datum', type: 'date', flex: 1, minWidth: 100, valueFormatter: (params) => formatDateTime(params.value) },
    ];

    const reportsColumns = [
        { field: 'author', headerName: 'Ime igrača', flex: 1, minWidth: 200 },
        { field: 'question', headerName: 'Tekst', flex: 3, minWidth: 300 },
        { field: 'respondedBy', headerName: 'Admin', flex: 1, minWidth: 150 },
        { field: 'response', headerName: 'Odgovor', flex: 3, minWidth: 300},
        { field: 'dateOpened', headerName: 'Datum pitanja', type: 'date', flex: 1, minWidth: 150, valueFormatter: (params) => formatDateTime(params.value) },
        { field: 'dateClosed', headerName: 'Datum odgovora', type: 'date', flex: 1, minWidth: 150, valueFormatter: (params) => formatDateTime(params.value) },
    ];

    return ( 
        <>
            <PageTitle title='RageMP - Staff - Logovi'/>

            <Grid container justifyContent={'center'} spacing={2} marginBottom={'15px'}>
                <Grid item container justifyContent={'center'} spacing={2}>
                    <Grid item xs={12}>
                        <DataTable 
                            title={'Kazne'}
                            columns={punishmentColumns}
                            getData={getPunishments}
                            loadData={true}
                            density={'compact'}
                            getRowId={(row) => row.id}
                            initialSort={{
                                field: 'date',
                                sort: 'desc'
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DataTable 
                            title={'Reportovi'}
                            columns={reportsColumns}
                            getData={getReports}
                            loadData={true}
                            density={'compact'}
                            getRowId={(row) => row.id}
                            initialSort={{
                                field: 'dateOpened',
                                sort: 'desc'
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </> 
    );
}
 
export default RageStaffLogsPage;