import DataTable from '../../../components/DataTable/DataTable';
import { formatDateTime, formatDateTimeWithSeconds } from '../../../helpers/timeFormatter';
import InfoModal from '../../../components/Abstracts/Modals/InfoModal';
import { useState } from 'react';
import { IconButton } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { getRageLogs, resolveLogProblem } from '../../../services/rageServices';
import useNotification from '../../../components/common/useNotification';
import ConfirmModal from '../../../components/Abstracts/Modals/ConfirmModal';
import { formatErrorMessage, formatSuccessMessage } from '../../../components/Alerts/AlertBuilder';


const ProblemLogsPage = ({
    pageId,
    tableTitle,
}) => {
    const { showError } = useNotification();
    const [moreInfoDialog, setMoreInfoDialog] = useState({isOpen: false, text: ''});
    const [reload, setReload] = useState(false);
    const [resolveProblemDialog, setResolveProblemDialog] = useState({ isOpen: false, problem: undefined, resultState: undefined, loading: false });

    const handleRowClick = (params) => {
        const data = params.row;
        const infoObj = (
            <div style={{ display: 'flex', gap: '10px', flexDirection: 'column', justifyContent: 'space-between'}}>
                <p><strong style={{marginRight: '5px'}}>Tip: </strong>{styleProblemType(data.type)}</p>
                <p><strong style={{marginRight: '5px'}}>SocialClub: </strong>{data.playerSocialClub}</p>
                <p><strong style={{marginRight: '5px'}}>Igrač: </strong>{data.playerName}</p>
                <p><strong style={{marginRight: '5px'}}>Datum: </strong>{formatDateTime(data.date)}</p>
                <p><strong style={{marginRight: '5px'}}>Naslov: </strong>{data.title}</p>
                <p><strong style={{marginRight: '5px'}}>Opis: </strong>{data.description}</p>
            </div>
        )

        setMoreInfoDialog(s => ({ ...s, isOpen: true, text: infoObj }));
    };
    
    const onCloseMoreInfoDialog = () => {
        setMoreInfoDialog(s => ({ ...s, isOpen: false}));
    }

    const onResolveButtonClick = (e, params) => {
        e.preventDefault();
        
        setResolveProblemDialog(s => ({ ...s, isOpen: true, problem: params.row, resultState: undefined, loading: false }))
    }

    const resolveProblemDialogDialogConfirm = (e) => {
        e.preventDefault();

        const { problem } = resolveProblemDialog;
        if(!problem) return;

        setResolveProblemDialog(s => ({ ...s, loading: true }))

        resolveLogProblem(pageId, problem.id).then(res => {
            if(res.status === 200)
            {
                setReload(true);
                let msg = 'Problem uspešno označen kao rešen!';
                if(res.data > 0)
                    msg = `Ovaj problem i ${res.data} sličnih su označeni kao rešeni!`;

                setResolveProblemDialog(s => ({ ...s, resultState: formatSuccessMessage(msg) }))
            }
        }).catch(e => {
            console.error(e);
            showError(`Došlo je do greške prilikom rešavanja problema!`);
            setResolveProblemDialog(s => ({ ...s, resultState: formatErrorMessage('Došlo je do greške prilikom rešavanja problema!') }))
        })
    }
    const onCloseResolveProblemDialog = (e) => {
        e.preventDefault();

        setResolveProblemDialog(s => ({ ...s, isOpen: false, problem: undefined, resultState: undefined, loading: false }))
    }
    
    const styleProblemType = (value, params) => {
        let bcColor = undefined;
        switch(value)
        {
            case 'ERROR':
                bcColor = '#e74c3c';
                break;
            case 'WARNING':
                bcColor = '#e67e22';
                break;
            case 'INFO':
                bcColor = '#3498db';
                break;
            case 'DEBUG':
                bcColor = '#34495e';
                break;
            default:
                bcColor = '#FF00FF';
                break;
        }
        
        return (
            <span 
                style={{
                    padding: '3px 6px',
                    borderRadius: '5px',
                    backgroundColor: bcColor,
                    fontWeight: '500px',
                    color: '#F7F7F7',
                    cursor: params ? 'pointer' : undefined,
                }}
                onClick={params ? () => handleRowClick(params) : undefined}
            >
                {value}
            </span>
        )
    }

    const tableColumns = [
        { field: 'type', headerName: 'Tip', flex: 0.5, minWidth: 100, align: 'center', 
            renderCell: (params) => styleProblemType(params.row.type, params) 
        },
        { field: 'playerSocialClub', headerName: 'SocialClub', flex: 1, minWidth: 100 },
        { field: 'playerName', headerName: 'Igrač', flex: 1, minWidth: 100 },
        { field: 'title', headerName: 'Naslov', flex: 2, minWidth: 100 },
        { field: 'description', headerName: 'Detaljnije', flex: 5, minWidth: 100 },
        { field: 'date', headerName: 'Datum', type: 'date', flex: 1, minWidth: 180, valueFormatter: (params) => formatDateTimeWithSeconds(params.value) },
        { field: 'actions', headerName: 'Akcije', flex: 0.5, minWidth: 60, sortable: false,
            renderCell: (params) =>{
                return (
                <>
                    <IconButton 
                        color='success'
                        onClick={(e) => onResolveButtonClick(e, params)}
                        title='Označi kao rešeno'
                    >
                        <CheckCircleOutlineIcon/>
                    </IconButton>
                </>
                )
            } 
        },
    ];

    return ( 
        <>
            <InfoModal 
                title={<div><i className="bx bx-package" style={{marginRight: '10px'}}></i>Detalji o problemu</div>}
                text={moreInfoDialog.text}
                open={moreInfoDialog.isOpen}
                handleClose={onCloseMoreInfoDialog}
            />

            <ConfirmModal 
                title={
                    <div 
                        style={{
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center'
                        }}>
                            <CheckCircleOutlineIcon style={{marginRight: '10px'}} />Označavanje problema kao rešenog
                    </div>
                }
                text={`Da li zaista želite da označite problem: ${resolveProblemDialog.problem?.title} i sve slične kao rešene?`}
                open={resolveProblemDialog.isOpen}
                handleClose={onCloseResolveProblemDialog}
                handleOk={resolveProblemDialogDialogConfirm}
                resultState={resolveProblemDialog.resultState}
                loading={resolveProblemDialog.loading}
            />

            <DataTable 
                title={tableTitle}
                columns={tableColumns}
                getData={() => getRageLogs(pageId)}
                loadData={true}
                density={'standard'}
                getRowId={(row) => row.id}
                initialSort={{
                    field: 'date',
                    sort: 'desc'
                }}
                reloadData={reload}
                resetReloadData={() => setReload(false)}
            />
        </> 
    );
}
 
export default ProblemLogsPage;