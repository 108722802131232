import React, { useState } from 'react';
import InputTextField from './InputTextField';
import { IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


const InputPassword = (inputTextfieldProps) => {

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = (e) => {
        e.preventDefault();

        setShowPassword((show) => !show)
    };

	return (
		<InputTextField
			{...inputTextfieldProps}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
		/>
	);
}

export default InputPassword;