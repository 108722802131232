import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import Layout from '../components/Layout/Layout';
import HomePage from '../pages/Home/HomePage';
import NotFoundPage from '../pages/ErrorPages/NotFound/NotFoundPage';
import LoginPage from '../pages/Auth/Login/LoginPage';
import Pages from '../constants/pages';
import RegisterPage from '../pages/Auth/Register/RegisterPage';
import UserManagementPage from '../pages/UserManagement/UserManagementPage';
import PermissionManagementPage from '../pages/PermissionManagement/PermissionManagementPage';
import SampJobsPage from '../pages/Samp/JobsPage/SampJobsPage';
import ConfigurationPage from '../pages/Configuration/ConfigurationPage';
import SampFakeAccountsPage from '../pages/Samp/FakeAccounts/SampFakeAccountsPage';
import SampCheatersPage from '../pages/Samp/Cheaters/SampCheatersPage';
import SampChangeNamesPage from '../pages/Samp/ChangeNames/SampChangeNamesPage';
import RageMoneyPage from '../pages/Rage/Money/RageMoneyPage';
import RageDonatorPage from '../pages/Rage/Donators/RageDonatorPage';
import RagePlayersPage from '../pages/Rage/Players/RagePlayersPage';
import RageCefLogsPage from '../pages/Rage/LogPages/RageCefLogsPage';
import RageClientLogsPage from '../pages/Rage/LogPages/RageClientLogsPage';
import SocialNetworksPage from '../pages/SocialNetworks/SocialNetworksPage';
import RagePromotionsPage from '../pages/Rage/Promotions/RagePromotionsPage';
import RageStaffPage from '../pages/Rage/Staff/RageStaffPage';
import OrdersPage from '../pages/Orders/OrdersPage';
import RageOrgLawEnforcementPage from '../pages/Rage/Organizations/RageOrgLawEnforcementPage';
import RageStaffLogsPage from '../pages/Rage/Staff/RageStaffLogsPage';
import RageOrgCriminalsPage from '../pages/Rage/Organizations/RageOrgCriminalsPage';
import RageBansPage from '../pages/Rage/Bans/RageBansPage';
import RageConnLogPage from '../pages/Rage/LogPages/LogTables/RageConnLogPage';
import RageDeathLogPage from '../pages/Rage/LogPages/LogTables/RageDeathLogPage';
import RageArrestLogPage from '../pages/Rage/LogPages/LogTables/RageArrestLogPage';
import RageFractionLog from './../pages/Rage/LogPages/LogTables/RageFractionLog';
import RageStockLogPage from './../pages/Rage/LogPages/LogTables/RageStockLogPage';
import RageKupoprodajaLogPage from '../pages/Rage/LogPages/LogTables/RageKupoprodajaLogPage';

export const ProtectedRoute =  () => {
    const isLoggedIn = localStorage.getItem('userData');
    return isLoggedIn
        ? (<Outlet />)
        : (<Navigate to={Pages.LOGIN} />)
};

export const PublicRoute = () => {
    const isLoggedIn = localStorage.getItem('userData');
    return isLoggedIn
        ? (<Navigate to={Pages.HOME_PAGE} />)
        : (<Outlet />)
};


const NavRouter = () => {
    return ( 
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<PublicRoute />}>
                    <Route path={Pages.LOGIN} element={<LoginPage />} />
                    <Route path={Pages.REGISTER} element={<RegisterPage />} />
                </Route>
                <Route path="/" element={<ProtectedRoute />}>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<HomePage />} />
                        <Route path={Pages.USER_MANAGEMENT} element={<UserManagementPage />} />
                        <Route path={Pages.PERMISSION_MANAGEMENT} element={<PermissionManagementPage />} />

                        {/* Konfiguracija */}
                        <Route path={Pages.CONFIGURATION} element={<ConfigurationPage />} />

                        {/* RageMP */}
                        <Route path={Pages.RAGE_MONEY_PAGE} element={<RageMoneyPage />} />
                        <Route path={Pages.RAGE_DONATOR_PAGE} element={<RageDonatorPage />} />
                        <Route path={Pages.RAGE_PLAYERS_PAGE} element={<RagePlayersPage />} />
                        <Route path={Pages.RAGE_PROMOTIONS_PAGE} element={<RagePromotionsPage />} />
                        <Route path={Pages.RAGE_CEF_LOGS_PAGE} element={<RageCefLogsPage />} />
                        <Route path={Pages.RAGE_CLIENT_LOGS_PAGE} element={<RageClientLogsPage />} />
                        <Route path={Pages.RAGE_CONN_LOGS_PAGE} element={<RageConnLogPage />} />
                        <Route path={Pages.RAGE_DEATH_LOGS_PAGE} element={<RageDeathLogPage />} />
                        <Route path={Pages.RAGE_ARREST_LOGS_PAGE} element={<RageArrestLogPage />} />
                        <Route path={Pages.RAGE_FRACTION_LOGS_PAGE} element={<RageFractionLog />} />
                        <Route path={Pages.RAGE_STOCK_LOGS_PAGE} element={<RageStockLogPage />} />
                        <Route path={Pages.RAGE_KUPOPRODAJA_LOGS_PAGE} element={<RageKupoprodajaLogPage />} />
                        <Route path={Pages.RAGE_STAFF_PAGE} element={<RageStaffPage />} />
                        <Route path={Pages.RAGE_STAFF_LOGS_PAGE} element={<RageStaffLogsPage />} />
                        <Route path={Pages.RAGE_ORG_LAW_ENFORCEMENT_PAGE} element={<RageOrgLawEnforcementPage />} />
                        <Route path={Pages.RAGE_ORG_CRIMINALS_PAGE} element={<RageOrgCriminalsPage />} />
                        <Route path={Pages.RAGE_BANS_PAGE} element={<RageBansPage />} />

                        {/* SAMP S1*/}
                        <Route path={Pages.SAMP_S1_JOBS_PAGE} element={<SampJobsPage serverNum={1} />} />
                        <Route path={Pages.SAMP_S1_FAKE_ACCOUNTS_PAGE} element={<SampFakeAccountsPage serverNum={1} />} />
                        <Route path={Pages.SAMP_S1_CHEATERS_PAGE} element={<SampCheatersPage serverNum={1} />} />
                        <Route path={Pages.SAMP_S1_CHANGE_NAMES_PAGE} element={<SampChangeNamesPage serverNum={1} />} />

                        {/* SAMP S2*/}
                        <Route path={Pages.SAMP_S2_JOBS_PAGE} element={<SampJobsPage serverNum={2} />} />
                        <Route path={Pages.SAMP_S2_FAKE_ACCOUNTS_PAGE} element={<SampFakeAccountsPage serverNum={2} />} />
                        <Route path={Pages.SAMP_S2_CHEATERS_PAGE} element={<SampCheatersPage serverNum={2} />} />
                        <Route path={Pages.SAMP_S2_CHANGE_NAMES_PAGE} element={<SampChangeNamesPage serverNum={2} />} />

                        {/* Ostalo */}
                        <Route path={Pages.SOCIAL_NETWORKS_PAGE} element={<SocialNetworksPage />} />
                        <Route path={Pages.ORDERS_PAGE} element={<OrdersPage />} />

                        <Route path="*" element={<NotFoundPage />} />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
 
export default NavRouter;