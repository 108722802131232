import { CircularProgress } from '@mui/material';
import React from 'react';
import CustomCard from './CustomCard';

const CardWithLoader = ({ 
    header,
    contentLoading,
    children,
    ...otherProps
}) =>
{
    return (
        <CustomCard 
            title={header}
            {...otherProps}
        >
            {
                contentLoading ?
                (<CircularProgress />)
                :
                (<>{children}</>)
            } 
        </CustomCard>
    );
}

export default CardWithLoader;