import React from 'react';

const MenuTitle = ({title}) => {
    return ( 
        <li className="menu-title">
            {title}
        </li> 
    );
}
 
export default MenuTitle;