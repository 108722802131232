import React, { useState } from 'react';
import Android12Switch from '../Android12Switch';
import { setUserApprovedStatus } from '../../../services/userManagement';

const UserActiveSwitch = ({ user }) => {
    const [isActive, setActive] = useState(user.isApproved);
    const [loading, setLoading] = useState(false);

    const onSwitchChange = (e) => {
        const checked = e.target.checked;
        setLoading(true);

        setUserApprovedStatus(user.id, checked).then(res => {
            setLoading(false);
            if(res.status === 200)
                setActive(checked);
        }).catch(e => {
            setLoading(false);
            console.error(e);
        });
    }

    return (  
        <Android12Switch 
            onChange={onSwitchChange}
            checked={isActive}
            disabled={loading}
            
        />
    );
}
 
export default UserActiveSwitch;