import { Alert, AlertTitle } from '@mui/material';
import React from 'react';

const ModalResultState = ({ resultState }) => {
    return (  
        <Alert severity={resultState.severity} style={{backgroundColor: '#2A3042'}}>
            <AlertTitle>{resultState.title}</AlertTitle>
            {resultState.message}
        </Alert>
    );
}
 
export default ModalResultState;