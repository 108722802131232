import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { formatNumber } from '../../helpers/numberFormatter';

const AreaChart = ({
    title,
    subtitle,
    categories,
    series,
    type,
    getData,
    loadData,
    reloadData,
    resetReloadData,
    seriesName,
    tooltip,
    yAxisValueFormatter = (v) => formatNumber(v)
}) => {
    const [seriesData, setSeriesData] = useState({ name: seriesName, data: [], categories: []});
    const [isLoading, setLoading] = useState(false);
    const [graphInited, setGraphInited] = useState(false);

    useEffect(() => {
        if(getData && ((loadData && !graphInited) || reloadData))
        {
            setLoading(true);
            getData().then(res => {
                setLoading(false);
                if(res.status === 200)
                    setSeriesData(s => ({ ...s, data: res.data.map(d => d.y), categories: res.data.map(d => d.x)}));

            }).catch(e => {
                setLoading(false);
                console.error(e);
            });

            if(!graphInited)
                setGraphInited(true);

            if(reloadData)
                resetReloadData();
        }
    }, [loadData, reloadData]);


    return (  
        <div 
            style={{
                paddingTop: '20px',
                paddingRight: '10px',
                background: '#2A3042'
            }}
        >
            <ReactApexChart
                options={{
                    xaxis: {
                        labels: {
                            datetimeUTC: false
                        },
                        type: type,
                        categories: categories ?? seriesData.categories
                    },
                    yaxis: {
                        labels: {
                            formatter: yAxisValueFormatter
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    title: {
                        offsetY: -5,
                        text: title,
                        align: 'left',
                        style: {
                            fontFamily: 'inherit',
                            fontSize: '16px'
                        }
                    },
                    subtitle: {
                        text: subtitle,
                        align: 'left'
                    },
                    theme: {
                        mode: 'dark',
                        palette: 'palette1'
                    },
                    chart: {
                        background: '#2A3042',
                        zoom: {
                            autoScaleYaxis: true,
                        },
                        toolbar: {
                            show: true,
                            tools: {
                                download: false,
                            }
                        }
                    },
                    grid: {
                        borderColor: '#FFFFFF44'
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shadeIntensity: 0.5,
                            inverseColors: false,
                            opacityFrom: 0.7,
                            opacityTo: 0.25,
                            stops: [0, 90, 100]
                          },
                    },
                    tooltip: tooltip,
                    
                }}
                series={series ?? (seriesData ? [seriesData] : undefined)}
                type='area'
            />
        </div>
    );
}

AreaChart.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    type: PropTypes.oneOf(['category', 'datetime', 'numeric']),
    loadData: PropTypes.bool,
    getData: PropTypes.func,
    reloadData: PropTypes.bool,
    resetReloadData: PropTypes.func
}
 
export default AreaChart;