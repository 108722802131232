export const formatNumber = (amount, minFractionDigits = 0, maxFractionDigits = 0) => {
    const numberFormatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: minFractionDigits,
        maximumFractionDigits: maxFractionDigits
    });

    return numberFormatter.format(amount);
}

export const formatCurrency = (amount, minFractionDigits = 0, maxFractionDigits = 0, currency = 'EUR') => {
    const numberFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: minFractionDigits,
        maximumFractionDigits: maxFractionDigits
    });

    return numberFormatter.format(amount);
}