import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import InfoCard from '../../../components/Cards/InfoCard';
import DataTable from '../../../components/DataTable/DataTable';
import PageTitle from '../../../components/common/PageTitle';
import { formatDate } from '../../../helpers/timeFormatter';
import { getPlayersCountHourly, getPlayersCountLast24h, getPlayersPage, getRegistrationsDaily, getTopLevelPlayers } from '../../../services/rageServices';
import useNotification from '../../../components/common/useNotification';
import { formatNumber } from '../../../helpers/numberFormatter';
import ColumnChart from '../../../components/Charts/ColumnChart';
import AreaChartAdvanced from '../../../components/Charts/AreaChartAdvanced';

const RagePlayersPage = () => {
    const { showError } = useNotification();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({ totalRegistrationsToday: 0, totalAccounts: 0, totalCharacters: 0, todayVisits: 0 });

    useEffect(() => {
        setLoading(true);
        getPlayersPage().then(res => {
            if(res.status === 200)
                setData(res.data);
        }).catch(e => {
            console.error(e);
            showError(`Došlo je do greške prilikom pribavljanja podataka za kartice!`);
        }).finally(() => {
            setLoading(false);
        })
    }, []);

    const playersWithMostBscColumns = [
        { field: 'uuid', headerName: 'UUID', flex: 1, minWidth: 100 },
        { field: 'name', headerName: 'Ime', flex: 1, minWidth: 100 },
        { field: 'level', headerName: 'Level', flex: 1, minWidth: 100, valueFormatter: (params) => formatNumber(params.value) },
        { field: 'characterCreated', headerName: 'Datum registracije', type: 'date', flex: 1, minWidth: 100, valueFormatter: (params) => formatDate(params.value) },
    ];

    return ( 
        <>
            <PageTitle title='RageMP - Players'/>

            <Grid container justifyContent={'center'} spacing={2} marginBottom={'15px'}>
                <Grid item md={3}>
                    <InfoCard
                        title={'Ukupno registracija danas'}
                        text={formatNumber(data.totalRegistrationsToday)}
                    >
                    </InfoCard>
                </Grid>
                <Grid item md={3}>
                    <InfoCard
                        title={'Ukupno naloga'}
                        text={formatNumber(data.totalAccounts)}
                    >
                    </InfoCard>
                </Grid>
                <Grid item md={3}>
                    <InfoCard
                        title={'Ukupno karaktera'}
                        text={formatNumber(data.totalCharacters)}
                    >
                    </InfoCard>
                </Grid>
                <Grid item md={3}>
                    <InfoCard
                        title={'Današnja poseta'}
                        text={formatNumber(data.todayVisits)}
                    >
                    </InfoCard>
                </Grid>

                <Grid item container justifyContent={'center'} spacing={2}>
                    <Grid item md={6} xs={12}>
                        <AreaChartAdvanced
                            title={'Ukupno igrača'}
                            getData={getPlayersCountHourly}
                            loadData={true}
                            seriesName={'Broj igrača'}
                            type='datetime'
                            tooltip={{x: { format: 'dd. MMM yyyy. HH:mm' }}}
                            colors={['#3399FF', '#00FF00', '#FF0000']}
                        />
                    </Grid>
                    
                    <Grid item md={6} xs={12}>
                        <ColumnChart
                            title={'Ukupno registracija'}
                            getData={getRegistrationsDaily}
                            loadData={true}
                            seriesName={'Registracije'}
                            type='datetime'
                            tooltip={{x: { format: 'dd. MMM yyyy.' }}}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <AreaChartAdvanced
                            title={'Igrači u poslednja 24 sata'}
                            getData={getPlayersCountLast24h}
                            loadData={true}
                            seriesName={'Broj igrača'}
                            type='datetime'
                            tooltip={{x: { format: 'dd. MMM yyyy. HH:mm' }}}
                            colors={['#3399FF', '#00FF00', '#FF0000']}
                            height={500}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <DataTable 
                            title={'Top Level igrači'}
                            columns={playersWithMostBscColumns}
                            getData={getTopLevelPlayers}
                            loadData={true}
                            density={'compact'}
                            loading={loading}
                            getRowId={(row) => row.uuid}
                            initialSort={{
                                field: 'level',
                                sort: 'desc'
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </> 
    );
}
 
export default RagePlayersPage;