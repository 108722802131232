export const formatErrorMessage = (message) => {
    return {
        severity: 'error',
        title: 'Error',
        message: message
    }
}

export const formatSuccessMessage = (message) => {
    return {
        severity: 'success',
        title: 'Success',
        message: message
    }
}

export const formatMessage = (result) => {
    const msg = result.data.message;
    return result.status === 200 ? formatSuccessMessage(msg) : formatErrorMessage(msg);
}