import ApiRoutes from '../constants/ApiRoutes';
import { getRequest } from './Api';

const selectServer = (serverNum, apiRoute) => {
    if(serverNum === 1)
        return apiRoute.replace('/Samp/', '/SampServerOne/');
    else if(serverNum === 2)
        return apiRoute.replace('/Samp/', '/SampServerTwo/');
    
    return apiRoute;
}

export const getJobsAbuse = (serverNum) => getRequest(selectServer(serverNum, ApiRoutes.Samp.GetJobsAbuse));
export const getFakeAccounts = (serverNum) => getRequest(selectServer(serverNum, ApiRoutes.Samp.GetFakeAccounts));
export const getCheaters = (serverNum) => getRequest(selectServer(serverNum, ApiRoutes.Samp.GetCheaters));
export const getPlayerNamesChanges = (serverNum) => getRequest(selectServer(serverNum, ApiRoutes.Samp.GetPlayerNamesChanges));