import axiosLib from 'axios';
import ApiRoutes from '../constants/ApiRoutes';
import Pages from '../constants/pages';
const axiosConfig = {
    baseURL: process.env.REACT_APP_PUBLIC_URL,
    headers: {
        "Content-Type": "application/json",
    },
    // `validateStatus` defines whether to resolve or reject the promise for a given
    // HTTP response status code. If `validateStatus` returns `true` (or is set to `null`
    // or `undefined`), the promise will be resolved; otherwise, the promise will be
    // rejected.
    // https://pipedream.com/community/t/faq-how-do-i-stop-axios-from-throwing-an-error-on-4xx-5xx-requests/923
    validateStatus: function (status) {
        return (status >= 200 && status < 300) || (status === 400); // ServerError -> Exception(-> catch()); Other errors -> then()
    },
}

const axios = axiosLib.create(axiosConfig);
const _axiosWithoutInterceptors = axiosLib.create(axiosConfig);

export const userLogin = (data) => {
    return _axiosWithoutInterceptors.post(ApiRoutes.Auth.Login, data);
}

export const userRegister = (data) => {
    return _axiosWithoutInterceptors.post(ApiRoutes.Auth.Register, data);
}

const refreshTokens = async () => {
    try 
    {
        const userData = localStorage.getItem('userData');
        if(!userData)
            return false;

        const response = await _axiosWithoutInterceptors.post(ApiRoutes.Auth.RefreshToken, JSON.parse(userData).tokens);
  
        const { newUserData } = response.data;

        if (response.status !== 200 || !newUserData?.tokens?.accessToken) {
            localStorage.removeItem('userData');
            return false;
        }
  
        localStorage.setItem('userData', JSON.stringify(newUserData));
        return newUserData.tokens;
    } 
    catch (error) 
    {
        console.error(error);
        localStorage.removeItem('userData');
        return false;
    }
};

let sentRequest = null;
axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalConfig = error?.config;
        if (error?.response?.status === 401 && !originalConfig?.sent) 
        {
            if(!error.response.headers.tokenexpired)
            {
                localStorage.removeItem('userData');
                window.location.href = Pages.LOGIN;
                return Promise.reject(error);
            }

            originalConfig.sent = true;
  
            if(!sentRequest)
                sentRequest = refreshTokens();

            const result = await sentRequest;
            sentRequest = null;
            if (!result || !result.accessToken) 
            {
                localStorage.removeItem('userData');
                window.location.href = Pages.LOGIN;
                return Promise.reject(error);
            }
                
            originalConfig.headers.Authorization = `Bearer ${result.accessToken}`;
            return axiosLib(originalConfig);
        }

        return Promise.reject(error);
    }
  );


axios.interceptors.request.use(
    config => {
        const userData = localStorage.getItem('userData');
        if (!config.headers.Authorization && userData) {
            config.headers.Authorization = `Bearer ${JSON.parse(userData).tokens.accessToken}`;
        }

      return config;
    },
    error => {
      return Promise.reject(error);
    },
  );


export const getRequest = (url, data = undefined) => {
    return axios.get(url, {
        params: data
    });
}

export const postRequest = (url, data) => {
    return axios.post(url, data);
}

export const putRequest = (url, data) => {
    return axios.put(url, data);
}

export const deleteRequest = (url) => {
    return axios.delete(url);
}