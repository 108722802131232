import React, { useEffect, useState } from 'react';
import InputModal from '../Abstracts/Modals/InputModal';
import { formatErrorMessage, formatSuccessMessage } from '../Alerts/AlertBuilder';
import InputCheckbox from '../FormParts/InputCheckbox';
import { updateUserRoles } from '../../services/userManagement';


const ChangeUserRolesModal = ({
    open,
    handleClose,
    user,
}) => {
    const [resultState, setResultState] = useState(undefined);
    const [formDisabled, setFormDisabled] = useState(false);

    const onSave = (data) => {

        const formattedData = [];
        Object.keys(data).forEach(id => {
            formattedData.push({
                id: Number(id),
                roleName: '',
                checked: data[id]
            });
        });

        setFormDisabled(true);
        updateUserRoles(user.id, formattedData).then(res => {
            setFormDisabled(false);
            if(res.status === 200)
                setResultState(formatSuccessMessage(res.data.message));
            else
                setResultState(formatErrorMessage('Došlo je do greške!'));
        }).catch(e => {
            setFormDisabled(false);
            console.error(e);
            setResultState(formatErrorMessage('Došlo je do greške! (2)'));
        });
    }

    const mapInitialState = (roles) => {

        const mappedState = {};
        roles?.forEach(role => {
            mappedState[role.id] = role.checked;
        })

        return mappedState;
    }

    useEffect(() => {
        if(!open)
        {
            setResultState(undefined);
            setFormDisabled(false);
        }
    }, [open])

    return (  
        <InputModal
            title={`Izmena permisija korisniku: ${user?.username}`}
            open={open}
            handleClose={handleClose}
            handleSave={onSave}
            initialState={mapInitialState(user?.roles ?? [])}
            disableForm={formDisabled}
            resultState={resultState}
        >
            {
                user?.roles.map((role, index) => {
                    return (
                        <InputCheckbox
                            key={index}
                            id={`${role.id}`}
                            label={role.roleName}
                            formControlLabelProps={{
                                style: {
                                    marginTop: '-10px',
                                    marginBottom: '-10px'
                                }
                            }}
                        />
                    )
                })
            }
        </InputModal>
    );
}
 
export default ChangeUserRolesModal;