import PageTitle from '../../../components/common/PageTitle';
import ProblemLogsPage from './ProblemLogsPage';

const RageClientLogsPage = () => {
    return ( 
        <>
            <PageTitle title='RageMP - Client Logs'/>

            <ProblemLogsPage 
                pageId={1}
                tableTitle={'Client Logs'}
            />
        </> 
    );
}
 
export default RageClientLogsPage;