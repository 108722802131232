import { useState } from 'react';
import PageTitle from '../../../../components/common/PageTitle';
import DataTable from '../../../../components/DataTable/DataTable';
import { formatDateTimeWithSeconds } from '../../../../helpers/timeFormatter';
import { getRageLogTable } from '../../../../services/rageServices';
import { RAGE_LOG_TABLE } from '../../../../constants/rageLogTables';
import { checkOrCrossIcon } from '../../../../components/common/CheckOrCross';

const RageStockLogPage = () => {

    const [reload, setReload] = useState(false);

    const tableColumns = [
        { field: 'id', headerName: 'Id', flex: 1, minWidth: 90, hide: true },
        { field: 'playerUuid', headerName: 'UUID', flex: 0.5, minWidth: 90 },
        { field: 'playerName', headerName: 'Ime', flex: 1.25, minWidth: 180 },
        { field: 'fractionId', headerName: 'Org Id', flex: 0.5, minWidth: 90 },
        { field: 'fractionName', headerName: 'Org ime', flex: 2, minWidth: 300 },
        { field: 'type', headerName: 'Type', flex: 1.75, minWidth: 250 },
        { field: 'amount', headerName: 'Amount', type: 'number', flex: 1, minWidth: 120 },
        { field: 'input', headerName: 'Input', align: 'center', flex: 0.5, minWidth: 90, renderCell: (params) => checkOrCrossIcon(params.value) },
        { field: 'date', headerName: 'Datum', type: 'date', flex: 1.25, minWidth: 165, valueFormatter: (params) => formatDateTimeWithSeconds(params.value) },
    ];

    return ( 
        <>
            <PageTitle title='RageMP - Stock Logs'/>

            <DataTable 
                title={'Skladišta - poslednja 15 dana'}
                columns={tableColumns}
                getData={() => getRageLogTable(RAGE_LOG_TABLE.StockLog)}
                loadData={true}
                getRowId={(row) => row.id}
                initialSort={{
                    field: 'date',
                    sort: 'desc'
                }}
                reloadData={reload}
                resetReloadData={() => setReload(false)}
            />
        </> 
    );
}
 
export default RageStockLogPage;