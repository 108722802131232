import React, { useEffect, useState } from 'react';
import './Footer.scss';
import UpdateIcon from '@mui/icons-material/Update';
import { forceCacheJob, getLastCacheUpdate } from '../../../services/home';
import LoadingIconButton from '../../Buttons/LoadingIconButton';
import useNotification from '../../common/useNotification';
import Restricted from '../../Permissions/Restricted';
import Permissions from '../../../constants/Permissions';

const Footer = () => {
    const [lastUpdateDate, setLastUpdateDate] = useState('Učitavanje...');
    const [refreshActive, setRefreshActive] = useState(false);
    const { showError } = useNotification();

    const onRefreshClick = (e) => {
        e.preventDefault();

        setRefreshActive(true);
        forceCacheJob().then(res => {
            setRefreshActive(false);
            if (res.status === 200)
                setLastUpdateDate(res.data.message);
            else
                showError(res.data.message);
        }).catch(e => {
            setRefreshActive(false);
            console.error(e);
            showError('Došlo je do greške prilikom pokretanja ažuriranja podataka!');
        });
    };

    useEffect(() => {
        getLastCacheUpdate().then(res => {
            if (res.status === 200)
                setLastUpdateDate(res.data.message);
        }).catch(e => {
            console.error(e);
        });
    }, []);

    return (
        <footer className="footer container-fluid footerContainer">
            {new Date().getFullYear()} © Balkan School Community

            <div
                style={{
                    fontStyle: 'italic',
                }}
            >
                        <Restricted to={Permissions.ADMINISTRATOR}>
                            <LoadingIconButton 
                                loading={refreshActive}
                                onClick={onRefreshClick}
                            > 
                                <UpdateIcon />
                            </LoadingIconButton>
                        </Restricted>
                        Poslednje ažuriranje: {lastUpdateDate}
            </div>
        </footer>
    );
};

export default Footer;