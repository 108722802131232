import React from 'react';
import usePermission from './usePermission';

const Restricted = ({to, fallback, loadingComponent, children, ...childrenProps}) => {

    const [loading, allowed] = usePermission(to);

    if(loading)
        return <>{loadingComponent}</>;

    if(allowed)
        return (
            Object.keys(childrenProps).length === 0 ?
            <>
                {children}
            </>
            :
            React.Children.map(children, child => {
                if (React.isValidElement(child))
                {
                    return React.cloneElement(child, childrenProps);
                }   
                
                return child;
            })
        )

    return <>{fallback}</>;
};

export default Restricted;