import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import InfoCard from '../../../components/Cards/InfoCard';
import DataTable from '../../../components/DataTable/DataTable';
import PageTitle from '../../../components/common/PageTitle';
import { formatDateTime } from '../../../helpers/timeFormatter';
import { getRageBansPage } from '../../../services/rageServices';
import useNotification from '../../../components/common/useNotification';
import { formatNumber } from '../../../helpers/numberFormatter';
import { MANAGEMENT_DATA_ID } from '../../../constants/managementDataId';
import { getManagementData } from '../../../services/dataService';

const RageBansPage = () => {
    const { showError } = useNotification();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({ bannedAccounts: 0, bannedCharacters: 0 });

    useEffect(() => {
        setLoading(true);
        getRageBansPage().then(res => {
            if(res.status === 200)
                setData(res.data);
        }).catch(e => {
            console.error(e);
            showError(`Došlo je do greške prilikom pribavljanja podataka za kartice!`);
        }).finally(() => {
            setLoading(false);
        })
    }, []);

    const bannedAccountsColumns = [
        { field: 'id', headerName: 'Id', flex: 1, minWidth: 100, type: 'number', hide: true, valueFormatter: (params) => params.value },
        { field: 'username', headerName: 'Username', flex: 1, minWidth: 150 },
        { field: 'socialClub', headerName: 'SocialClub', flex: 1, minWidth: 120 },
        { field: 'socialClubId', headerName: 'SocialClub Id', flex: 1, minWidth: 120, type: 'number', hide: true, valueFormatter: (params) => params.value },
        { field: 'hwid', headerName: 'HWID', flex: 1, minWidth: 400, hide: true },
        { field: 'reason', headerName: 'Reason', flex: 3, minWidth: 300 },
        { field: 'admin', headerName: 'Admin', flex: 1, minWidth: 180 },
        { field: 'banTime', headerName: 'Datum bana', type: 'date', flex: 1, minWidth: 150, valueFormatter: (params) => formatDateTime(params.value) },
        { field: 'expTime', headerName: 'Datum isteka', type: 'date', flex: 1, minWidth: 150, valueFormatter: (params) => formatDateTime(params.value) },
    ];

    const bannedCharactersColumns = [
        { field: 'id', headerName: 'Id', flex: 1, minWidth: 100, type: 'number', hide: true, valueFormatter: (params) => params.value },
        { field: 'uuid', headerName: 'UUID', flex: 0.5, minWidth: 70, type: 'number', valueFormatter: (params) => params.value },
        { field: 'characterName', headerName: 'Ime karaktera', flex: 1, minWidth: 180 },
        { field: 'ip', headerName: 'IP', flex: 1, minWidth: 140 },
        { field: 'socialClub', headerName: 'SocialClub', flex: 1, minWidth: 120 },
        { field: 'socialClubId', headerName: 'SocialClub Id', flex: 1, minWidth: 120, type: 'number', hide: true, valueFormatter: (params) => params.value },
        { field: 'hwid', headerName: 'HWID', flex: 1, minWidth: 400, hide: true },
        { field: 'reason', headerName: 'Reason', flex: 3, minWidth: 300 },
        { field: 'admin', headerName: 'Admin', flex: 1, minWidth: 180 },
        { field: 'banTime', headerName: 'Datum bana', type: 'date', flex: 1, minWidth: 150, valueFormatter: (params) => formatDateTime(params.value) },
        { field: 'expTime', headerName: 'Datum isteka', type: 'date', flex: 1, minWidth: 150, valueFormatter: (params) => formatDateTime(params.value) },
    ];

    return ( 
        <>
            <PageTitle title='RageMP - Banovi'/>

            <Grid container justifyContent={'center'} spacing={2} marginBottom={'15px'}>
                <Grid item md={3}>
                    <InfoCard
                        title={'Banovanih naloga'}
                        text={formatNumber(data.bannedAccounts)}
                    >
                    </InfoCard>
                </Grid>
                <Grid item md={3}>
                    <InfoCard
                        title={'Banovanih karaktera'}
                        text={formatNumber(data.bannedCharacters)}
                    >
                    </InfoCard>
                </Grid>

                <Grid item container justifyContent={'center'} spacing={2}>
                    <Grid item xs={12}>
                        <DataTable 
                            title={'Banovani nalozi'}
                            columns={bannedAccountsColumns}
                            getData={getManagementData}
                            additionalParamsData={{
                                dataId: MANAGEMENT_DATA_ID.RageBannedAccounts
                            }}
                            serverSide={true}
                            loadData={true}
                            density={'compact'}
                            loading={loading}
                            getRowId={(row) => row.id}
                            initialSort={{
                                field: 'expTime',
                                sort: 'asc'
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DataTable 
                            title={'Banovani karakteri'}
                            columns={bannedCharactersColumns}
                            getData={getManagementData}
                            additionalParamsData={{
                                dataId: MANAGEMENT_DATA_ID.RageBannedCharacters
                            }}
                            serverSide={true}
                            loadData={true}
                            density={'compact'}
                            loading={loading}
                            getRowId={(row) => row.id}
                            initialSort={{
                                field: 'expTime',
                                sort: 'asc'
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </> 
    );
}
 
export default RageBansPage;