import React, { useEffect, useState } from 'react';
import MySQLConfigForm from '../../../components/Forms/MySQLConfigForm';
import { Grid } from '@mui/material';
import { getSampConfiguration } from '../../../services/configuration';
import useNotification from '../../../components/common/useNotification';

const SampConfigSection = () => {
    const [data, setData] = useState({ sampDb_S1: {}, sampLogsDb_S1: {}, sampDb_S2: {}, sampLogsDb_S2: {} })
    const { showError } = useNotification();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getSampConfiguration().then(res => {
            if(res.status === 200)
                setData(res.data);
        }).catch(e => {
            console.error(e);
            showError(`Došlo je do greške prilikom pribavljanja SAMP konfiguracije!`);
        }).finally(() => {
            setLoading(false);
        })
    }, []);

    return (  
        <>
            <Grid container spacing={2}>
                <Grid item container spacing={2}>
                    <Grid item xs={12} sm={6} lg={4}>
                        <MySQLConfigForm 
                            title={'SAMP #1 - Main'}
                            dataKey='sampDb_S1'
                            data={data}
                            handleSetData={setData}
                            pageLoading={loading}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <MySQLConfigForm 
                            title={'SAMP #1 - Logs'}
                            dataKey='sampLogsDb_S1'
                            data={data}
                            handleSetData={setData}
                            pageLoading={loading}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={2}>
                    <Grid item xs={12} sm={6} lg={4}>
                        <MySQLConfigForm 
                            title={'SAMP #2 - Main'}
                            dataKey='sampDb_S2'
                            data={data}
                            handleSetData={setData}
                            pageLoading={loading}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <MySQLConfigForm 
                            title={'SAMP #2 - Logs'}
                            dataKey='sampLogsDb_S2'
                            data={data}
                            handleSetData={setData}
                            pageLoading={loading}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
 
export default SampConfigSection;