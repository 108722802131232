import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#3399FF',
        },
        secondary: {
            main: '#c3cbe4',
        },
        success: {
            main: '#34c38f',
        },
        info: {
            main: '#50a5f1',
        },
        warning: {
            main: '#f1b44c',
        }
    },
    typography: {
        fontFamily: 'inherit',
        
        button: {
            textTransform: 'none',
        }
    },
});

export default function CustomTheme({ children })
{
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
}