import React, { useState } from 'react';
import Android12Switch from '../../../components/Switches/Android12Switch';
import { switchRageServerState } from '../../../services/configuration';
import { FormControlLabel, Typography } from '@mui/material';
import useNotification from '../../../components/common/useNotification';

const RageServerOnlineSwitch = ({
    isRageServerOnline,
    handleServerActive,
    pageLoading,
    ...otherProps
}) => {
    const [loading, setLoading] = useState(false);
    const { showSuccess, showError } = useNotification();

    const onRegistrationSwitchState = (e) => {
        setLoading(true);
        switchRageServerState().then(res => {
            if(res.status === 200)
            {
                const isActive = res.data;
                handleServerActive(isActive);
                showSuccess(<Typography>Uspešno promenjen status RageMP servera na: <strong style={{ color: isActive ? '#00FF00' : '#FF0000'}}>{(isActive ? "aktivan" : "neaktivan")}</strong></Typography>)
            }
        }).catch(e => {
            console.error(e);
            showError('Došlo je do greške prilikom promene statusa Rage servera!');
        }).finally(() => {
            setLoading(false);
        });
    }

    return (  
        <FormControlLabel 
            control={
            <Android12Switch 
                onChange={onRegistrationSwitchState}
                checked={isRageServerOnline}
                disabled={loading || pageLoading}
            />}
            label={<Typography color={isRageServerOnline ? 'primary' : 'error'}>Server Online</Typography >}
            {...otherProps}
        />
    );
}
 
export default RageServerOnlineSwitch;