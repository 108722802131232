import React, { useState } from 'react';

const CustomMetisMenu = ({ 
    id, 
    className, 
    children 
}) => {

    const [titles, setActiveTitles] = useState([]);

    const onMenuItemClick = (allTitles) => {
        setActiveTitles(allTitles);
    }

    return (  
        <ul
            id={id}
            className={className}
        >
        {
            React.Children.map(children, child => {
                if (React.isValidElement(child))
                {
                    return React.cloneElement(child, { 
                        handleMenuItemClick: onMenuItemClick,
                        activeTitles: titles
                    });
                }
                    
                return child;
            })
        }
        </ul>
    );
}
 
export default CustomMetisMenu;