import { Box, DialogContent, Modal } from '@mui/material';
import React from 'react';
import FormAbstract from '../FormAbstract';
import ModalTitle from './ModalTitle';
import ModalResultState from './ModalResultState';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: '#2A3042',
    border: '2px solid #000',
    boxShadow: 24,
    borderRadius: 4,
};
  

const InputModal = ({ 
    title,
    open, 
    handleClose, 
    handleSave,
    initialState, 
    disableForm, 
    resultState,
    children, 
}) => {

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <ModalTitle title={title} handleClose={handleClose} />
                <DialogContent style={{padding: '16px'}}>
                {
                    resultState ?
                    (
                        <ModalResultState resultState={resultState} />
                    ) 
                    :
                    (
                        <FormAbstract
                            buttonText={'Save'}
                            handleSave={handleSave}
                            children={children}
                            initialState={initialState}
                            disableForm={disableForm}
                            buttonProps={{
                                loading: disableForm
                            }}
                        />
                    )
                }
                </DialogContent>
            </Box>
        </Modal>
    );
}
 
export default InputModal;