import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';


export const checkOrCrossIcon = (isCheck) => {

    switch(isCheck)
    {
        case true:
            return <CheckCircleOutlineIcon color='success'/>
        case false:
            return <CancelIcon color='error'/>
        default:
            return <></>
    }
}