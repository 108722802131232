import React, { useEffect, useState } from 'react';
import PageTitle from '../../components/common/PageTitle';
import { Alert, Grid, Stack } from '@mui/material';
import ServerInfoCard from '../../components/Cards/ServerInfoCard';
import { getAlerts, getRageServerData, getSAMPServerData } from '../../services/home';

const HomePage = () => {
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        getAlerts().then(res => {
            if(res.status === 200)
                setErrors(res.data);
        }).catch(e => {
            console.error(e);
        });
    }, []);
    return ( 
        <>
            <PageTitle title='Početna'/>

            <Stack sx={{ width: '100%', padding: '2.5px', marginBottom: '12.5px' }} spacing={2}>
                {
                    errors.map((err, index) => {
                        return (
                            <Alert key={index} variant="filled" severity={err.severity}>
                                {err.message}
                            </Alert>
                        )
                    })
                }  
            </Stack>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6} xl={4}>
                    <ServerInfoCard
                        title={'RageMP'} 
                        apiForDataRequest={getRageServerData}
                    />
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                    <ServerInfoCard
                        title={'SAMP #1 Vinewood'} 
                        apiForDataRequest={() => getSAMPServerData(1)}
                    />
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                    <ServerInfoCard
                        title={'SAMP #2 Downtown'} 
                        apiForDataRequest={() => getSAMPServerData(2)}
                    />
                </Grid>
            </Grid>
        </>
    );
}
 
export default HomePage;