/*
Template Name: Skote - Responsive Bootstrap 4 Admin Dashboard
Author: Themesbrand
Version: 2.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Js File
*/
import $ from 'jquery'
import 'bootstrap/dist/js/bootstrap.min.js'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const AppOLD = () => {
    function initMenuItemScroll() {
        // focus active menu in left sidebar
        $(document).ready(function () {
            if ($("#sidebar-menu").length > 0 && $("#sidebar-menu .mm-active .active").length > 0) {
                let activeMenu = $("#sidebar-menu .mm-active .active").offset().top;
                if (activeMenu > 300) {
                    activeMenu = activeMenu - 300;
                    $(".simplebar-content-wrapper").animate({ scrollTop: activeMenu }, "slow");
                }
            }
        });
    }

    function initDropdownMenu() {
        $('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
            if (!$(this).next().hasClass('show')) {
                $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
            }
            var $subMenu = $(this).next(".dropdown-menu");
            $subMenu.toggleClass('show');

            return false;
        });
    }

    function initComponents() {
        $(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });

        $(function () {
            $('[data-toggle="popover"]').popover();
        });
    }

    const location = useLocation()

    useEffect(() => {
        initMenuItemScroll();
        initDropdownMenu();
        initComponents();
        $('#status').fadeOut();
        $('#preloader').delay(350).fadeOut('slow');
    },[location])

    return ( <></> );
}
 
export default AppOLD; 