import React from 'react';
import './TopBar.scss';
import { Link } from "react-router-dom";
import BsLogo from '../../../assets/images/_bs/logo.png';
import BsLogoText from '../../../assets/images/_bs/logo-text.png';
import Pages from '../../../constants/pages';
import $ from 'jquery';
import Notifications from './Notifications/Notifications';
import DropdownUserOptions from './UserOptions/DropdownUserOptions';

const TopBar = () => {

    const onVerticalMenuBtnClick = (e) => {
        e.preventDefault();

        $('.main-container').toggleClass('sidebar-enable');
        if ($(window).width() >= 992) {
            $('.main-container').toggleClass('vertical-collpsed');
        } else {
            $('.main-container').removeClass('vertical-collpsed');
        }
    };

    return (
        <header id="page-topbar" className="header">
            <div className="header__left">
                <Link to={Pages.HOME_PAGE} className="header__logo">
                    <span className="header__logo-sm">
                        <img src={BsLogo} alt="" height="22" />
                    </span>
                    <span className="header__logo-lg">
                        <img src={BsLogoText} alt="" height="45" />
                    </span>
                </Link>

                <button onClick={onVerticalMenuBtnClick} type="button" className="header__collapse-button" id="vertical-menu-btn">
                    <i className="fa fa-fw fa-bars"></i>
                </button>
            </div>

            <div className="header__right">
                <Notifications />
                <DropdownUserOptions />
            </div>
        </header>
    );
};

export default TopBar;

