import React from 'react';
import { SnackbarProvider } from 'notistack';
import NavRouter from './navigation/NavRouter';
import './assets/scss/base.scss';
import CustomTheme from './theme';
import PermissionProvider from './components/Permissions/PermissionProvider';

function App() {
    return (
        <CustomTheme>
			<PermissionProvider>
				<SnackbarProvider 
					anchorOrigin={{ 
						vertical: 'bottom', 
						horizontal: 'right' 
					}}
				>
					<NavRouter />
				</SnackbarProvider>
			</PermissionProvider>
        </CustomTheme>
    );
}

export default App;
