import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab'
import PropTypes from 'prop-types'
  
const childrenDivStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
}

const FormAbstract = ({ 
    buttonText, 
    buttonProps, 
    handleSave, 
    children, 
    initialState, 
    disableForm 
}) => {
    const [dataState, setDataState] = useState(initialState ?? {});
    const [savePressed, setSavePressed] = useState(false);

    const setData = (id, value) => {
        setDataState(s => ({ ...s, [id]: value}));
    }

    const onSave = (e) => {
        e.preventDefault();

        const initialData = {};
        setSavePressed(true);

        let containsErrors = false;
        React.Children.forEach(children, child => {
            if(React.isValidElement(child) && typeof child.props.id !== 'undefined')
            {
                initialData[child.props.id] = undefined;

                if(isChildEmptyAndRequired(child.props, true))
                    containsErrors = true;
            }
        })

        if(containsErrors)
            return;

        const data = { ...initialData, ...dataState };
        handleSave(data);
    }

    const isChildEmptyAndRequired = (childProps, savePressed) => {
        if(childProps.required && !dataState[childProps.id] && savePressed === true)
            return true;

        return false;
    }

    useEffect(() => {
        setSavePressed(false);
    }, []);

    return (
        <div style={childrenDivStyle}>
            {
                React.Children.map(children, child => {
                    if (React.isValidElement(child) && typeof child.props.id !== 'undefined')
                    {
                        const id = child.props.id;
                        return React.cloneElement(child, { 
                            handleValueChange: setData, 
                            error: child.props.error || isChildEmptyAndRequired(child.props, savePressed),
                            style: { marginTop: '10px', marginBottom: '10px'},
                            value: dataState[id] || '',
                            disabled: child.props.disabled || disableForm
                        });
                    }
                        
                    return child;
                })
            }
            <LoadingButton 
                onClick={onSave} 
                style={{
                    alignSelf: 'center'
                }}
                disabled={disableForm}
                variant='contained'
                {...buttonProps}
            >
                <span>{buttonText}</span>
            </LoadingButton>
        </div>
    );
}

FormAbstract.propTypes = {
    buttonText: PropTypes.string, 
    buttonProps: PropTypes.object, 
    handleSave: PropTypes.func, 
    initialState: PropTypes.object, 
    disableForm: PropTypes.bool
}
 
export default FormAbstract;