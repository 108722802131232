import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import InfoCard from '../../../components/Cards/InfoCard';
import AreaChart from '../../../components/Charts/AreaChart';
import DataTable from '../../../components/DataTable/DataTable';
import PageTitle from '../../../components/common/PageTitle';
import { formatDate } from '../../../helpers/timeFormatter';
import { getBscByDays, getBscDiffByTime, getDonatorsPage, getMostBscAccounts } from '../../../services/rageServices';
import useNotification from '../../../components/common/useNotification';
import { formatNumber } from '../../../helpers/numberFormatter';

const RageDonatorPage = () => {
    const { showError } = useNotification();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({ totalBsc: 0, dayBscDiff: 0, monthBscDiff: 0, autoDonationsNumToday: 0, totalBscByAutoDonationToday: 0 });

    useEffect(() => {
        setLoading(true);
        getDonatorsPage().then(res => {
            if(res.status === 200)
                setData(res.data);
        }).catch(e => {
            console.error(e);
            showError(`Došlo je do greške prilikom pribavljanja podataka za kartice!`);
        }).finally(() => {
            setLoading(false);
        })
    }, []);

    const playersWithMostBscColumns = [
        { field: 'username', headerName: 'Username', flex: 1, minWidth: 100 },
        { field: 'bsc', headerName: 'BSC', type: 'number', flex: 1, minWidth: 100, valueFormatter: (params) => formatNumber(params.value) },
        { field: 'character1', headerName: '1. karakter', flex: 1, minWidth: 100 },
        { field: 'character2', headerName: '2. karakter', flex: 1, minWidth: 100 },
        { field: 'character3', headerName: '3. karakter', flex: 1, minWidth: 100 },
        { field: 'accountRegistered', headerName: 'Datum registracije', type: 'date', flex: 1, minWidth: 100, valueFormatter: (params) => formatDate(params.value) },
    ];

    return ( 
        <>
            <PageTitle title='RageMP - Donatori'/>

            <Grid container justifyContent={'center'} spacing={2} marginBottom={'15px'}>
                <Grid item md={3}>
                    <InfoCard
                        title={'BSC - Ukupno'}
                        text={formatNumber(data.totalBsc)}
                    >
                    </InfoCard>
                </Grid>
                <Grid item md={3}>
                    <InfoCard
                        title={'Dnevna razlika'}
                        text={formatNumber(data.dayBscDiff)}
                    >
                    </InfoCard>
                </Grid>
                <Grid item md={3}>
                    <InfoCard
                        title={'Mesečna razlika'}
                        text={formatNumber(data.monthBscDiff)}
                    >
                    </InfoCard>
                </Grid>
                <Grid item md={3}>
                    <InfoCard
                        title={'Broj automatskih donacija - danas'}
                        text={formatNumber(data.autoDonationsNumToday)}
                    >
                    </InfoCard>
                </Grid>
                <Grid item md={3}>
                    <InfoCard
                        title={'Ukupan BSC dodeljen putem auto donacija'}
                        text={formatNumber(data.totalBscByAutoDonationToday)}
                    >
                    </InfoCard>
                </Grid>

                <Grid item container justifyContent={'center'} spacing={2}>
                    <Grid item md={6} xs={12}>
                        <AreaChart
                            title={'Ukupno BSC'}
                            getData={getBscByDays}
                            loadData={true}
                            seriesName={'BSC'}
                            type='datetime'
                            tooltip={{x: { format: 'dd. MMM yyyy. HH:mm' }}}
                        />
                    </Grid>
                    
                    <Grid item md={6} xs={12}>
                        <AreaChart
                            title={'BSC razlika'}
                            getData={getBscDiffByTime}
                            loadData={true}
                            seriesName={'Razlika'}
                            type='datetime'
                            tooltip={{x: { format: 'dd. MMM yyyy. HH:mm' }}}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <DataTable 
                            title={'Igrači sa najviše BSC'}
                            columns={playersWithMostBscColumns}
                            getData={getMostBscAccounts}
                            loadData={true}
                            density={'compact'}
                            loading={loading}
                            getRowId={(row) => row.username}
                            initialSort={{
                                field: 'bsc',
                                sort: 'desc'
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </> 
    );
}
 
export default RageDonatorPage;