import React, { useEffect } from 'react';
import { useState } from 'react';
import PageTitle from '../../../components/common/PageTitle';
import RudarVocarCarinikTable from './RudarVocarCarinikTable';
import { Grid } from '@mui/material';
import JobsBetweenTimeTables from './JobsBetweenTimeTables';
import JobsPerDaysTables from './JobsPerDaysTables';
import { getJobsAbuse } from '../../../services/sampServices';

const SampJobsPage = ({
    serverNum
}) => {
    const [data, setData] = useState({ 
        rudarVocarCarinikToday: [],  rudarVocarCarinik7Days: [], 
        jobDataBetween02and06: [], jobDataBetween00and09: [],
        jobDataForToday: [], jobDataFor7Days: [],
    });
    const [isLoading, setLoading] = useState(false);


    useEffect(() => {
        setLoading(true);

        getJobsAbuse(serverNum).then(res => {
            if(res.status === 200)
                setData(res.data);
        }).catch(e => {
            console.error(e);
        }).finally(() => {
            setLoading(false);
        })
    }, [serverNum]);

    return ( 
        <>
            <PageTitle title='Poslovi'/>
            <Grid container spacing={2}>
                <RudarVocarCarinikTable 
                    dataForToday={data.rudarVocarCarinikToday}
                    dataForWeek={data.rudarVocarCarinik7Days}
                    loading={isLoading}
                />

                <JobsBetweenTimeTables 
                    dataBetween02and06={data.jobDataBetween02and06}
                    dataBetween00and09={data.jobDataBetween00and09}
                    loading={isLoading}
                />

                <JobsPerDaysTables 
                    dataForToday={data.jobDataForToday}
                    dataFor7Days={data.jobDataFor7Days}
                    loading={isLoading}
                />
            </Grid>
        </> 
    );
}
 
export default SampJobsPage;