import { CircularProgress, IconButton } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types'

const LoadingIconButton = ({ loading, onClick, children, ...props }) => {
    return (  
        <IconButton 
            disabled={loading}
            onClick={onClick}
            {...props}
        >
            {
                loading ? 
                (
                    <CircularProgress size={24}/>
                )
                :
                children
            }
        </IconButton>
    );
}

LoadingIconButton.propTypes = {
    loading: PropTypes.bool,
    onClick: PropTypes.func,
}
 
export default LoadingIconButton;