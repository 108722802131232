import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';

const TopPromoterWeeklyChartHeader = ({ filters, setFilters }) => {

    return (  
        <Grid container display={'flex'} gap={'20px'} justifyContent={'flex-end'} width={'100%'}>
            <Grid item>
                <FormControl style={{ minWidth: '100px'}}>
                    <InputLabel id="days-label">Dani</InputLabel>
                    <Select
                        id="days"
                        value={filters['days']}
                        label="Dani"
                        onChange={(e) => setFilters({ ...filters, days: e.target.value })}
                    >
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={14}>14</MenuItem>
                        <MenuItem value={21}>21</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={60}>60</MenuItem>
                        <MenuItem value={90}>90</MenuItem>
                        <MenuItem value={180}>180</MenuItem>
                        <MenuItem value={360}>360</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl style={{ minWidth: '120px'}}>
                    <InputLabel id="topPromoters-label">Top Promoteri</InputLabel>
                    <Select
                        id="topPromoters"
                        value={filters['topPromoters']}
                        label="Top Promoteri"
                        onChange={(e) => setFilters({ ...filters, topPromoters: e.target.value })}
                    >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}
 
export default TopPromoterWeeklyChartHeader;