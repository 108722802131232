import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import InfoCard from '../../../components/Cards/InfoCard';
import AreaChart from '../../../components/Charts/AreaChart';
import DataTable from '../../../components/DataTable/DataTable';
import PageTitle from '../../../components/common/PageTitle';
import { formatMoney } from '../../../helpers/moneyFormatter';
import { formatDate, formatDateTime } from '../../../helpers/timeFormatter';
import { getBusinessesMoney, getRageMoneyByDays, getRageMoneyDiffByTime, getRageMoneyPage, getWealthiestPlayers } from '../../../services/rageServices';
import useNotification from '../../../components/common/useNotification';

const RageMoneyPage = () => {
    const { showError } = useNotification();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({ totalMoney: 0, dayMoneyDiff: 0, monthMoneyDiff: 0, mostMoneyEver: 0, mostMoneyEverDate: null, totalMoneyInBusinesses: 0, totalMoneyInAtms: 0, avgMoneyPerPlayer: 0 });

    useEffect(() => {
        setLoading(true);
        getRageMoneyPage().then(res => {
            if(res.status === 200)
                setData(res.data);
        }).catch(e => {
            console.error(e);
            showError(`Došlo je do greške prilikom pribavljanja podataka za kartice!`);
        }).finally(() => {
            setLoading(false);
        })
    }, []);

    const wealthiestPlayersColumns = [
        { field: 'uuid', headerName: 'UUID', flex: 1, minWidth: 100 },
        { field: 'name', headerName: 'Ime', flex: 1, minWidth: 100 },
        { field: 'cash', headerName: 'Džep', type: 'number', flex: 1, minWidth: 100, valueFormatter: (params) => formatMoney(params.value) },
        { field: 'bank', headerName: 'Banka', type: 'number', flex: 1, minWidth: 100, valueFormatter: (params) => formatMoney(params.value) },
        { field: 'total', headerName: 'Ukupno', type: 'number', flex: 1, minWidth: 100, valueFormatter: (params) => formatMoney(params.value),
            valueGetter: (params) => {
                const total = params.row.cash + params.row.bank;
                return total;
            }
        },
        { field: 'characterCreated', headerName: 'Datum registracije', type: 'date', flex: 1, minWidth: 100, valueFormatter: (params) => formatDate(params.value) },
    ];

    const businessesColumns = [
        { field: 'id', headerName: 'ID', flex: 0.5, minWidth: 100 },
        { field: 'type', headerName: 'Tip', flex: 1, minWidth: 100 },
        { field: 'owner', headerName: 'Vlasnik', flex: 1, minWidth: 100 },
        { field: 'money', headerName: 'Kasa', type: 'number', flex: 1, minWidth: 100, valueFormatter: (params) => formatMoney(params.value) },
    ];

    return ( 
        <>
            <PageTitle title='RageMP - Novac'/>

            <Grid container justifyContent={'center'} spacing={2} marginBottom={'15px'}>
                <Grid item md={3}>
                    <InfoCard
                        title={'Ukupno novca'}
                        text={formatMoney(data.totalMoney)}
                    >
                    </InfoCard>
                </Grid>
                <Grid item md={3}>
                    <InfoCard
                        title={'Dnevna razlika'}
                        text={formatMoney(data.dayMoneyDiff)}
                    >
                    </InfoCard>
                </Grid>
                <Grid item md={3}>
                    <InfoCard
                        title={'Mesečna razlika'}
                        text={formatMoney(data.monthMoneyDiff)}
                    >
                    </InfoCard>
                </Grid>
                <Grid item md={3}>
                    <InfoCard
                        title={'Najviše novca ikada'}
                        text={formatMoney(data.mostMoneyEver)}
                        bottomText={`Datum: ${formatDateTime(data.mostMoneyEverDate)}`}
                    >
                    </InfoCard>
                </Grid>
                <Grid item md={3}>
                    <InfoCard
                        title={'Ukupno novca u biznisima'}
                        text={formatMoney(data.totalMoneyInBusinesses)}
                    >
                    </InfoCard>
                </Grid>
                <Grid item md={3}>
                    <InfoCard
                        title={'Ukupno novca u bankomatima'}
                        text={formatMoney(data.totalMoneyInAtms)}
                    >
                    </InfoCard>
                </Grid>
                <Grid item md={3}>
                    <InfoCard
                        title={'Prosek novca po igraču'}
                        text={formatMoney(data.avgMoneyPerPlayer)}
                    >
                    </InfoCard>
                </Grid>

                <Grid item container justifyContent={'center'} spacing={2}>
                    <Grid item md={6} xs={12}>
                        <AreaChart
                            title={'Ukupno novca'}
                            getData={getRageMoneyByDays}
                            loadData={true}
                            seriesName={'Novac'}
                            type='datetime'
                            tooltip={{x: { format: 'dd. MMM yyyy. HH:mm' }}}
                        />
                    </Grid>
                    
                    <Grid item md={6} xs={12}>
                        <AreaChart
                            title={'Razlika u novcu'}
                            getData={getRageMoneyDiffByTime}
                            loadData={true}
                            seriesName={'Razlika'}
                            type='datetime'
                            tooltip={{x: { format: 'dd. MMM yyyy. HH:mm' }}}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <DataTable 
                            title={'Najbogatiji igrači'}
                            columns={wealthiestPlayersColumns}
                            getData={getWealthiestPlayers}
                            loadData={true}
                            density={'compact'}
                            loading={loading}
                            getRowId={(row) => row.name}
                            initialSort={{
                                field: 'total',
                                sort: 'desc'
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DataTable 
                            title={'Biznisi'}
                            columns={businessesColumns}
                            getData={getBusinessesMoney}
                            loadData={true}
                            density={'compact'}
                            loading={loading}
                            getRowId={(row) => row.id}
                            initialSort={{
                                field: 'money',
                                sort: 'desc'
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </> 
    );
}
 
export default RageMoneyPage;