import React, { useState } from 'react';
import '../AuthPage.scss';
import Pages from '../../../constants/pages';
import { Link, useNavigate } from 'react-router-dom';
import ProfileImg from "../../../assets/images/profile-img.png";
import BSLogo from '../../../assets/images/_bs/logo.png';
import PageTitle from '../../../components/common/PageTitle';
import BackgroundImage from '../../../assets/images/bg-auth-overlay.png';
import { login } from '../../../services/auth';
import FormAbstract from '../../../components/Abstracts/FormAbstract';
import InputCheckbox from '../../../components/FormParts/InputCheckbox';
import InputTextField from '../../../components/FormParts/InputTextField';
import useNotification from '../../../components/common/useNotification';
import InputPassword from '../../../components/FormParts/InputPassword';

const LoginPage = () => {
    const navigate = useNavigate();
    const { showError } = useNotification();
    const [loading, setLoading] = useState(false);

    const onLoginClick = (data) => {
        setLoading(true);
        login(data).then(res => {
            setLoading(false);
            if (res.status !== 200)
                showError(res.data.message);
            else {
                const { userData } = res.data;
                localStorage.setItem('userData', JSON.stringify(userData));
                navigate(Pages.HOME_PAGE);
            }
        }).catch(e => {
            setLoading(false);
            console.error(e);
            showError('Došlo je do greške prilikom logina! (2)');
        });
    };

    return (
        <div className="account-pages" style={{ backgroundImage: `url(${BackgroundImage})` }}>
            <PageTitle title="Login" onlyDocumentTitle={true} />
            <div className="auth">
                <div className="auth__header">
                    <div className="auth__header-text" >
                        <h5>Dobro došli nazad!</h5>
                        <p>Unesite pristupne podatke kako biste pristupili panelu.</p>
                    </div>
                    <img className="auth__header-img" src={ProfileImg} alt="" />
                </div>
                <div className="auth__body">
                    <div className="auth__logo">
                        <img className="auth__logo-img" src={BSLogo} alt="" />
                    </div>
                    <form>
                        <LoginForm
                            handleSave={onLoginClick}
                            loading={loading}
                        />
                        <div className="auth__change-page">
                            <Link to={Pages.REGISTER}>
                                <i className="fa fa-user-plus fa-1x" /> Nemate nalog? Registrujte se!
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
            <div className="auth-copyright">
                <p>© {new Date().getFullYear()}. Balkan School Community</p>
            </div>
        </div>
    );
};

export default LoginPage;

const LoginForm = ({ handleSave, loading }) => {
    return (
        <div>
            <FormAbstract
                handleSave={handleSave}
                buttonText='Login'
                buttonProps={{
                    fullWidth: true,
                    loading: loading,
                }}
            >
                <InputTextField
                    id="username"
                    label={'Korisničko ime'}
                    fullWidth
                    type="email"
                    required
                />

                <InputPassword
                    id="password"
                    label="Lozinka"
                    type="password"
                    fullWidth
                    required
                />

                <InputCheckbox
                    id="remberMe"
                    label={"Zapamti me"}
                />
            </FormAbstract>
        </div>
    );
};