import { Box, Button, CircularProgress, DialogContent, Modal } from '@mui/material';
import React from 'react';
import ModalTitle from './ModalTitle';
import ModalResultState from './ModalResultState';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 500,
    maxWidth: 1000,
    bgcolor: '#2A3042',
    border: '2px solid #000',
    boxShadow: 24,
    borderRadius: 4,
};
  

const InfoModal = ({ 
    title,
    text,
    open, 
    handleClose, 
    resultState,
    loading
}) => {

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <ModalTitle title={title} handleClose={handleClose} />
                <DialogContent style={{padding: '16px'}}>
                {
                    resultState ?
                    (
                        <ModalResultState resultState={resultState} />
                    ) 
                    :
                    (
                        <>
                            <div style={{ fontSize: '16px'}}>{text}</div>
                            <div style={{
                                display: 'flex',
                                marginTop: '16px',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                {
                                    loading ? 
                                    (
                                        <CircularProgress />
                                    )
                                    :
                                    (
                                        <>
                                            <Button onClick={handleClose} variant='outlined' style={{marginRight: '10px'}}>Ok</Button>
                                        </>
                                    )
                                }
                            </div>
                        </>
                    )
                }
                </DialogContent>
            </Box>
        </Modal>
    );
}
 
export default InfoModal;