import { Divider } from '@mui/material';
import React from 'react';

const NotificationItem = ({ title, text, divider }) => {
    return (
        <>
            <div className="text-reset notification-item">
                <div className="media">
                    <div className="media-body">
                        <h6 style={{ fontWeight: '500' }} className="font-size-13 mt-0 mb-1">{title}</h6>
                        <div className="font-size-12 text-muted">
                            <p className="mb-1">{text}</p>
                        </div>
                    </div>
                </div>
            </div>
            {
                divider && (<Divider />)
            }
        </>
    );
};

export default NotificationItem;