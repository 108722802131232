import React from 'react';
import { DialogTitle, Divider, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ModalTitle = ({ title, handleClose}) => {
    return (  
        <DialogTitle 
            style={{
                padding: '0px',
                marginBottom: '0px',
            }}
        >
            <div 
                style={{
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center', 
                    backgroundColor: 'primary',
                    padding: '10px'
                }}
            >
                {title}
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </div>
            
            <Divider/>
        </DialogTitle>
    );
}
 
export default ModalTitle;