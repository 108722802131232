import { Grid, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import InfoCard from '../../components/Cards/InfoCard';
import DataTable from '../../components/DataTable/DataTable';
import PageTitle from '../../components/common/PageTitle';
import { formatDateTime } from '../../helpers/timeFormatter';
import useNotification from '../../components/common/useNotification';
import { formatCurrency, formatNumber } from '../../helpers/numberFormatter';
import ColumnChart from '../../components/Charts/ColumnChart';
import { getAllOrders, getAllTransactions, getOrdersByDays, getOrdersNumByDaysByPaymentMethod, getOrdersPage, getRevenueByDays, getRevenueByDaysByPaymentMethod } from './../../services/ordersService';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import PendingIcon from '@mui/icons-material/Pending';

const OrdersPage = () => {
    const { showError } = useNotification();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({ 
        totalOrders: 0, 
        totalOrdersToday: 0, 
        rage: {
            totalCoins: 0,
            totalRevenue: 0,
            totalOrders: 0,
        },
        samp: {
            totalCoins: 0,
            totalRevenue: 0,
            totalOrders: 0,
        },
        totalRevenueByAutoDonationToday: 0,
        avgRevenueForLastWeek: 0,
        month: '',
        paypal: {
            totalRevenue: 0,
            totalRevenueToday: 0,
            totalOrders: 0,
            totalTransactions: 0,
        },
        creditCard: {
            totalRevenue: 0,
            totalRevenueToday: 0,
            totalOrders: 0,
            totalTransactions: 0,
        },
    });

    useEffect(() => {
        setLoading(true);
        getOrdersPage().then(res => {
            if(res.status === 200)
                setData(res.data);
        }).catch(e => {
            console.error(e);
            showError(`Došlo je do greške prilikom pribavljanja podataka za kartice!`);
        }).finally(() => {
            setLoading(false);
        })
    }, []);

    const checkOrCrossFormat = (isCheck, deliveryTime, status) => {
        if(isCheck)
            return <Tooltip title="Dostavljeno"><span><CheckCircleOutlineIcon color='success'/></span></Tooltip>

        return (
            deliveryTime ? 
            <Tooltip title={<>Došlo je do greške prilikom dostave<br/>{status}</>}><span><CancelIcon color='error'/></span></Tooltip>
            : 
            <Tooltip title={`Neobrađena porudžbina`}><span><PendingIcon color='warning'/></span></Tooltip>
        )
    }

    const checkOrCrossIcon = (isCheck) => {
        switch(isCheck)
        {
            case true:
                return <CheckCircleOutlineIcon color='success'/>
            case false:
                return <CancelIcon color='error'/>
            default:
                return <></>
        }
    }

    const ordersColumns = [
        { field: 'id', headerName: 'Id', type: 'number', flex: 1, minWidth: 100, hide: true },
        { field: 'paymentId', headerName: 'Payment Id', type: 'number', flex: 1, minWidth: 100, hide: true },
        { field: 'server', headerName: 'Server', flex: 1.5, minWidth: 100 },
        { field: 'playerId', headerName: 'Id igrača', type: 'number', flex: 1, minWidth: 100, hide: true },
        { field: 'playerUsername', headerName: 'Ime', flex: 2, minWidth: 150 },
        { field: 'productName', headerName: 'Produkt', flex: 1, minWidth: 150 },
        { field: 'productQuantity', headerName: 'Količina', type: 'number', flex: 1, minWidth: 100, valueFormatter: (params) => formatNumber(params.value) },
        { field: 'totalPrice', headerName: 'Cena', type: 'number', flex: 1, minWidth: 100, renderCell: (params) => formatCurrency(params.value, 0, 0, params.row.currency) },
        { field: 'status', headerName: 'Status', flex: 1, minWidth: 100, hide: true },
        { field: 'paymentSource', headerName: 'Payment Source', flex: 1, minWidth: 150, hide: true },
        { field: 'paymentMethod', headerName: 'Payment Method', flex: 1, minWidth: 150 },
        { field: 'isDelivered', headerName: 'Dostavljeno', align: 'center', flex: 1, minWidth: 100, renderCell: (params) => checkOrCrossFormat(params.row.isDelivered, params.row.deliveryTime, params.row.status) },
        { field: 'createTime', headerName: 'Datum kreiranja', type: 'date', flex: 1, minWidth: 150, valueFormatter: (params) => formatDateTime(params.value) },
        { field: 'deliveryTime', headerName: 'Datum dostave', type: 'date', flex: 1, minWidth: 150, valueFormatter: (params) => formatDateTime(params.value) },
    ];

    const transactionColumns = [
        { field: 'id', headerName: 'Id', type: 'number', flex: 1, minWidth: 100, valueFormatter: (params) => params.value },
        { field: 'playerUsername', headerName: 'Korisničko ime', flex: 1.5, minWidth: 130 },
        { field: 'totalPaid', headerName: 'Cena', type: 'number', flex: 1, minWidth: 100, renderCell: (params) => formatCurrency(params.value, 0, 0, params.row.currency) },
        { field: 'paymentMethod', headerName: 'Payment Method', flex: 1, minWidth: 150 },
        { field: 'status', headerName: 'Status', flex: 1, minWidth: 120 },
        { field: 'createTime', headerName: 'Datum kreiranja', type: 'date', flex: 1, minWidth: 150, valueFormatter: (params) => formatDateTime(params.value) },
        { field: 'payerName', headerName: 'Ime platioca', flex: 1.5, minWidth: 155 },
        { field: 'payerEmail', headerName: 'Email platioca', flex: 1.5, minWidth: 240 },
        { field: 'cardCountry', headerName: 'Zemlja', flex: 1, minWidth: 70},
        { field: 'code', headerName: 'Kod greške', type: 'number', flex: 1, minWidth: 100},
        { field: 'message', headerName: 'Poruka', flex: 2, minWidth: 300, hide: true},
        { field: 'redirections', headerName: 'Redir.', flex: 1, minWidth: 70},
        { field: 'fromGame', headerName: 'Iz igre', align: 'center', flex: 0.5, minWidth: 80, renderCell: (params) => checkOrCrossIcon(params.row.fromGame)},
    ];

    return ( 
        <>
            <PageTitle title='Donatori'/>

            <Grid container justifyContent={'center'} spacing={2} marginBottom={'15px'}>
                <Grid item md={3}>
                    <InfoCard
                        title={'Ukupan broj porudžbina'}
                        text={formatNumber(data.totalOrders)}
                    >
                    </InfoCard>
                </Grid>
                <Grid item md={3}>
                    <InfoCard
                        title={'Broj porudžbina danas'}
                        text={formatNumber(data.totalOrdersToday)}
                    >
                    </InfoCard>
                </Grid>
                <Grid item md={3}>
                    <InfoCard
                        title={'Dodeljeno BSC-a danas'}
                        text={formatNumber(data.rage.totalCoins)}
                        bottomText={`Broj porudžbina: ${data.rage.totalOrders} (${formatCurrency(data.rage.totalRevenue)})`}
                    >
                    </InfoCard>
                </Grid>
                <Grid item md={3}>
                    <InfoCard
                        title={'Dodeljeno DPoena danas'}
                        text={formatNumber(data.samp.totalCoins)}
                        bottomText={`Broj porudžbina: ${data.samp.totalOrders} (${formatCurrency(data.samp.totalRevenue)})`}
                    >
                    </InfoCard>
                </Grid>
                <Grid item md={3}>
                    <InfoCard
                        title={`Paypal - ${data.month}`}
                        text={formatCurrency(data.paypal.totalRevenue)}
                        bottomText={<>
                            Uspešne porudžbine: {data.paypal.totalOrders} / {data.paypal.totalTransactions}<br/>
                            Danas: {formatCurrency(data.paypal.totalRevenueToday)}
                        </>}
                    >
                    </InfoCard>
                </Grid>
                <Grid item md={3}>
                    <InfoCard
                        title={`Platne kartice - ${data.month}`}
                        text={formatCurrency(data.creditCard.totalRevenue)}
                        bottomText={<>
                            Uspešne porudžbine: {data.creditCard.totalOrders} / {data.creditCard.totalTransactions}<br/>
                            Danas: {formatCurrency(data.creditCard.totalRevenueToday)}
                        </>}
                    >
                    </InfoCard>
                </Grid>
                <Grid item md={3}>
                    <InfoCard
                        title={'Ukupna zarada danas'}
                        text={formatCurrency(data.totalRevenueByAutoDonationToday)}
                        bottomText={`Prosek poslednjih sedam dana: ${formatCurrency(data.avgRevenueForLastWeek)}`}
                    >
                    </InfoCard>
                </Grid>

                <Grid item container justifyContent={'center'} spacing={2}>
                    <Grid item md={6} xs={12}>
                        <ColumnChart
                            title={'Ukupno porudžbina'}
                            getData={getOrdersByDays}
                            loadData={true}
                            seriesName={'Porudžbine'}
                            type='datetime'
                            tooltip={{x: { format: 'dd. MMM yyyy.' }}}
                            isStacked={true}
                        />
                    </Grid>
                    
                    <Grid item md={6} xs={12}>
                        <ColumnChart
                            title={'Ukupna zarada'}
                            getData={getRevenueByDays}
                            loadData={true}
                            seriesName={'Zarada'}
                            type='datetime'
                            tooltip={{x: { format: 'dd. MMM yyyy.' }}}
                            yaxisFormatter={(value) => formatCurrency(value)}
                            isStacked={true}
                        />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <ColumnChart
                            title={'Ukupno porudžbina po načinu plaćanja'}
                            getData={getOrdersNumByDaysByPaymentMethod}
                            loadData={true}
                            seriesName={'Porudžbine'}
                            type='datetime'
                            tooltip={{x: { format: 'dd. MMM yyyy.' }}}
                            isStacked={true}
                        />
                    </Grid>
                    
                    <Grid item md={6} xs={12}>
                        <ColumnChart
                            title={'Ukupna zarada po načinu plaćanja'}
                            getData={getRevenueByDaysByPaymentMethod}
                            loadData={true}
                            seriesName={'Zarada'}
                            type='datetime'
                            tooltip={{x: { format: 'dd. MMM yyyy.' }}}
                            yaxisFormatter={(value) => formatCurrency(value)}
                            isStacked={true}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <DataTable 
                            title={'Uspešne porudžbine'}
                            columns={ordersColumns}
                            getData={getAllOrders}
                            loadData={true}
                            density={'compact'}
                            loading={loading}
                            getRowId={(row) => row.id}
                            initialSort={{
                                field: 'createTime',
                                sort: 'desc'
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <DataTable 
                            title={'Sve transakcije'}
                            columns={transactionColumns}
                            getData={getAllTransactions}
                            loadData={true}
                            density={'compact'}
                            loading={loading}
                            getRowId={(row) => row.id}
                            initialSort={{
                                field: 'id',
                                sort: 'desc'
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </> 
    );
}
 
export default OrdersPage;