import React, { useEffect, useState } from 'react';
import { getRageConfiguration } from '../../../services/configuration';
import useNotification from '../../../components/common/useNotification';
import RageServerOnlineSwitch from './RageServerOnlineSwitch';

const RageConfigSection = () => {
    const [data, setData] = useState({ isRageServerOnline: false })
    const { showError } = useNotification();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getRageConfiguration().then(res => {
            if(res.status === 200)
                setData(res.data);
        }).catch(e => {
            console.error(e);
            showError(`Došlo je do greške prilikom pribavljanja RageMP konfiguracije!`);
        }).finally(() => {
            setLoading(false);
        })
    }, []);

    return (  
        <>
            <RageServerOnlineSwitch 
                isRageServerOnline={data.isRageServerOnline}
                handleServerActive={(checked) => setData(s => ({ ...s, isRageServerOnline: checked }))}
                pageLoading={loading}
                style={{ marginBottom: '10px'}}
            />
        </>
    );
}
 
export default RageConfigSection;